export default function rightPanelDetail(module, record, panelData = {}, callback = null){
    var detail_id = this.dataGet("rightPanel/data/detail/id")

    // pokud se snazi vyvolat zaznam, ktery je jiz otevren, tak ho zavreme
    if (record === detail_id){
        this.closeRightPanel();
        return;
    }
    else{
        this.openRightPanel("load");
    }

    this.dsClear();

    //v rightPanelu se zobrazi email
    if (module === "Emails") {
        this.dsAdd("set","rightPanel/content","emailView");
        const data = {
            uid: record,
            mbox: "sugar::Emails"
        }
        this.emailGetDetailRightPanel(data, "rightPanel");
    } else {
        //v rightPanelu se zobrazi normalni detail
        const paramData = {
            subpanels:false,
        }
        const data = {
            id: record,
            module,
            prefix: "rightPanel/data/detail",
            type:"rightPanel"
        }
        this.dsAdd("set","rightPanel/data",panelData)
        this.dsAdd("set","rightPanel/content","detailView");
        this.routeDetailView(data, paramData, callback);
    }
}