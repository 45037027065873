import detailDefault from "../../detailDefault";

export default class detailProductTemplates extends detailDefault {
  load(sAction, data) {
    this.setAttributesFields(
      sAction,
      data.prefix,
      sAction.dataGet(data.prefix + '/fields/urceni/value'),
    );
  }

  update(sAction, data) {
    if (data.field === 'urceni') {
      this.setAttributesFields(
        sAction,
        data.prefix,
        sAction.dataGet(data.prefix + '/changes/fields/urceni'),
      );
    }
  }

  setAttributesFields(sAction, prefix, urceni) {
    let attrMap = {};

    switch (urceni) {
      case '1':
        attrMap = {
          'rozmer': 'Rozmer_pneu',
          'prumer_kola': 'Prumer_kola',
          'sirka_kola': 'Sirka_kola',
          'profil_rafku': 'Prumer_rafku',
          'sirka_rafku': 'Pocet_platen_index',
          'typ_pneu': 'Typ_pneu',
          'profil_kola': 'Profil_kola',
        };

        break;
      case '2':
      case '3':
        attrMap = {
          'rozmer': 'Rozmer_pneu',
          'prumer_kola': 'Prumer_kola',
          'sirka_kola': 'Sirka_kola',
          'profil_rafku': 'Prumer_rafku',
          'sirka_rafku': 'Rim_pocet_platen',
          'typ_pneu': 'Typ_pneu',
          'profil_kola': 'Profil_kola',
        };

        break;

      case '4':
        attrMap = {
          'rozmer': 'Rozmer_vidlic',
          'prumer_kola': 'Nosnost',
          'sirka_kola': 'Sirka',
          'profil_rafku': 'Trida_iso',
          'sirka_rafku': 'Delka',
          'typ_pneu': '',
          'profil_kola': '',
        };

        break;
    }

    const attrNameEnum = sAction.app_strings.dynamicEnum['304814f1-cf71-ccb7-c4eb-65f8455d7053']

    Object.entries(attrMap).map((el) => {
      if (el[1]) {
        sAction.dataSet(prefix + '/fields/' + el[0] + '/def/vname', attrNameEnum[el[1]]);
        sAction.dataSet(prefix + '/fields/' + el[0] + '/def/visible', true);
      } else {
        sAction.dataSet(prefix + '/fields/' + el[0] + '/def/visible', false);
      }
    });
  }
}
