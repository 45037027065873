export default function confrim(message, callback, color = null, continueLabel = this.translate("LBL_CONTINUE", "Home")) {
  const data = {
    // Potvrzení
    header: this.translate("LBL_CONFIRM"),
    color: color,
    buttons: [
      // Zrušit
      { label: this.translate("LBL_CANCEL", "Home"), callback: () => this.popupHide() },

      // Pokračovat
      { label: continueLabel, callback: callback },
    ],
  };

  this.popup(message, data);
}
