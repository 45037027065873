import React from "react";
import MaterialDefault from "./MaterialDefault";
import SelectWindow from "./SelectWindow";
import FormControl from "@material-ui/core/FormControl";
import sAction from "sAction";

export default class TabSelect extends MaterialDefault {
  constructor(props) {
    super(props);
    var open = false;

    var label = null;
    this.props.options.forEachObject((obj,key) => {
      obj.forEach((line,lineKey) => {
        if(line.value == this.props.defaultValue){
          label = line.label
        }
      })
    })


    this.state = {
      focus: false,
      openOnFocus: true,
      open: open,
      label: label,
    };

    this.ref = React.createRef();
  }

  onChange(params) {
    this.setState({
      value: params.value,
      open: false,
      label: params.label,
      index: params.index,
    });
    params.numOfTabSelect = this.props.numOfTabSelect;  //in case we have multiple tab selects and need to differentiate which changed value
    if (this.props.onChange != undefined) {
      this.props.onChange(params);
    }
  }

  onClose(e) {
    this.setState({ open: false });
    setTimeout(() => {}, 100);
    if (this.props.onClose != undefined) {
      this.props.onClose(e);
    }
  }

  onOpen(e) {
    this.setState({ open: true });
    if (this.props.onOpen != undefined) {
      this.props.onOpen(e);
    }
  }

  onBlur(e) {
    if (this.props.onBlur != undefined) {
      this.props.onBlur(e);
    }
  }

  onKeyDown(e) {
    if (e.keyCode === 13 && this.state.open === false) {
      this.setState({ open: true });
    }

    if (this.props.onKeyDown != undefined) {
      this.props.onKeyDown(e);
    }
  }

  onClick(e) {
    this.setState({ open: true });
  }

  onFocus(e) {
    if (this.props.onFocus !== undefined) {
      this.props.onFocus(e);
    }
  }

  getElement = () => {
    return this.ref;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.clearLabel === true) {
      this.setState({ label: null });
    }
  }

  render() {
    const options = this.props.options;

    var className = "acmSelectContainer";
    if (this.state.focus == true) {
      className = "acmSelectContainer focus";
    }
    if (this.props.error == true) {
      className += " error";
    }

    let container = document.getElementById(this.props.container);
    let isPopupOpen = null;
    if (sAction.getNewPopupId()) {
      //todo parent should provide element name, hopefully fix this in future
      container = document.getElementsByClassName("acmPopup popupShow")[0];
      isPopupOpen = true;
    }

    var selectWindow = (
      <SelectWindow
        className="acmSelect"
        id={this.props.id}
        inputProps={{ id: this.props.id }}
        onChange={(e) => this.onChange(e)}
        onOpen={(e) => this.onOpen(e)}
        onClose={(e) => this.onClose(e)}
        onBlur={(e) => this.onBlur(e)}
        onFocus={(e) => this.onFocus(e)}
        onClick={(e) => this.onChange(e)}
        inputRef={this.props.myRef}
        open={this.state.open}
        autoFocus={this.props.autoFocus}
        name={this.props.name}
        options={options}
        container={container}
        isPopupOpen={isPopupOpen}
        getParentElement={this.getElement}
        defaultValue={this.props.defaultValue}
        activeTabName={this.props.activeTabName || ""}
      />
    );

    return (
      <FormControl
        className={
          this.props.containerClassName != undefined
            ? className + " " + this.props.containerClassName
            : className
        }
        disabled={this.props.disabled}
        onKeyDown={(e) => this.onKeyDown(e)}
        onClick={() => (this.state.open == false ? this.onClick() : null)}
        autoFocus={this.props.autoFocus}
        ref={this.ref}
      >
        <div className="selectLabel" style={this.props.labelStyle}>
          {this.state.label ?? this.props.label}
        </div>
          <svg className="MuiSvgIcon-root MuiSelect-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M7 10l5 5 5-5z"/>
          </svg>
        {this.state.open && selectWindow}
      </FormControl>
    );
  }
}
