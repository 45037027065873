import React, {useState, useEffect} from 'react';
import sAction from 'sAction';
import Loader from '../../loader';
import PropTypes from 'prop-types';

const ErrorLogs = (props) => {
    const [logData, setLogData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        sAction.rest.post('getErrorLogs', [], (returnData) => {
            if (!returnData.status) {
                sAction.error(returnData.message.text);
                return;
            }
            setLogData(processLogData(returnData.message.data));
            setLoading(false);
        }, false);
    }, []);

    const processLogData = (logData) => {
        logData.forEach((log) => {
            try {
                log['error_log'] = JSON.parse(log['error_log']);
            } catch (e) {
                console.log('[ERROR] json parse error_log', log['error_log'].substring( 0, 80) + '...');
            }
        });
        return logData;
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className='customReportContainer'>
            <div className='customReport adminErrorLogs'>
                <div className='customReportHeader errorLogsAdminHeader'>
                    <div className='headerIconErrorLogs'>
                        <i className='iconfas-error_logs'></i>
                    </div>
                    <h2 className='adminErrorLogsHeader'>{sAction.app_strings['LBL_ERROR_LOGS']}</h2>
                </div>

                <div className='customReportContent'>
                    <div className='customReportTable'>
                        <div className='errorLogTableHeader errorLogGridLine'>
                            <p>Typ</p>
                            <p>Id</p>
                            <p>Date</p>
                            <p>User</p>
                            <p>Error</p>
                            <p>Url</p>
                            <p>Last click</p>
                        </div>
                        <div className='errorLogTableBody'>
                            {logData.map((item, index) => {
                                let typeClass = 'info_tag';
                                switch( item?.type){
                                    case 'error':
                                        typeClass = 'error_tag';
                                        break;
                                    case 'slow-loading':
                                    case 'warning':
                                        typeClass = 'warning_tag';
                                }
                                return (
                                    <div className='errorLogLineLink' key={`error_log_id${index}`}>
                                        <div className='errorLogTableBodyLine errorLogGridLine'>
                                            <a href={'#admin/custom/error_log_detail/' + item?.log_id} className='lineTagA'><p className={'lineTag ' + typeClass} >{item?.type ? item?.type : 'log'}</p></a>
                                            <a href={'#admin/custom/error_log_detail/' + item?.log_id}><p className='errorLogId'>{item?.log_id}</p></a>
                                            <p className='errorLogDate'>{item?.timestamp}</p>
                                            <p><a href={'#detail/Users/' + item.user_id}>{item?.user?.user_name}</a></p>
                                            <p>{item?.label}</p>
                                            <p><a href={item?.url}>{item?.url}</a></p>
                                            <p>{item?.click_item}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <p>logs: {logData.length}/200 &nbsp; (shows last 200 logs)</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

ErrorLogs.propTypes = {
    resetButtons: PropTypes.func.isRequired,
    addButton: PropTypes.func.isRequired,
};


export default ErrorLogs;
