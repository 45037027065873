export default function accessRequestCreatePortalUser(data) {
    this.confrim(this.translate('LBL_ACCESS_REQUEST_CREATE_PORTAL_USER_CONFIRM'), () => {
        this.load();
        this.rest.post('accessRequestCreatePortalUser', {record: data.id}, (returnData) => {
            this.unLoad();
            this.popupHide();
            if (returnData.status) {
                this.href(`#detail/acm_portal_users/${returnData.message.data.record}`);
            } else {
                this.error(this.translate(returnData.errorMessage?.text));
            }
        });
    });
}
