import moment from 'moment';
import detailDefault from '../../detailDefault';

export default class detailacm_service_checklist extends detailDefault {
    afterSave(sAction) {
        if (sAction.deviceType === 'servis') {
            setTimeout(() => {
                sAction.updateServisInfo();
            }, 800);
        }
    }

    load(sAction, data) {
        // if is new record generate a new code
        if (sAction.dataGet(data.prefix + '/fields/id/value') === null) {
            this.generateName(sAction, data);
        }
    }

    generateName(sAction, data) {
        const prefix = data.prefix;
        const assignedUserId = sAction.dataGet(prefix + '/fields/assigned_user_name/def/id_value');
        const searchData = {
            fields: ['id'],
            getFields: ['name', 'id', 'technician_id'],
            value: assignedUserId,
            module: 'Users',
        };

        sAction.quickSearch(searchData, (data) => {
            if (data.length <= 0 || data[0]?.technician_id === undefined) {
                console.warn('quickSearch can not find user');
                return;
            }
            let technicianId = data[0].technician_id;
            if (!technicianId) {
                console.warn('technicianId is null');
                technicianId = 'N/A';
            }

            const recordName = `${technicianId} - ${moment().format('DD. MM. YYYY')}`;
            sAction.dsClear();
            sAction.dsAdd('set', prefix + '/fields/name/value', recordName);
            sAction.dsAdd('set', prefix + '/changes/fields/name', recordName);
            sAction.dsProcess();
        });
    }
}
