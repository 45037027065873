export default function portalUserMassAssignRoles(data) {
    const users = [data.id];

    this.load();
    this.rest.post('getAdminData', {}, (retData) => {
        const branches = [];
        this.unLoad();
        if (retData.status) {
            branches.push(retData.message.parent);
            retData.message.accounts.forEach((acc, accId) => {
                branches.push(acc);
            });
            this.popup('PortalAdminMassAssignUsers', {
                header: this.translate('LBL_MASS_ASSIGN_ROLE_ACCOUNT', 'acm_portal_users'),
                data: {
                    roles: retData.message.roles,
                    branches: branches,
                    callback: (params) => {
                        this.load();

                        const setParams = {
                            users,
                            roles: params.roles,
                            branches: params.branches,
                        };

                        this.rest.post('userMassAssignRoles', setParams, (retDataAss) => {
                            this.unLoad();
                            if (retDataAss.status) {
                                console.log('retDataAss', retDataAss);
                                this.alert(this.translate('LBL_MASSUPDATE_USER_SUCEESS', 'acm_portal_users'));
                            } else {
                                this.error(retDataAss.errorMessage);
                            }
                        });
                    },
                },

            });
        }
    });
}
