import React from "react";
import InputField from "../inputFieldClass";
import InputText from "../../formElements/InputText";
import sAction from "sAction";
import CustomTooltip from "../../detailView/CustomTooltip"
import Loader from "../../loader";

export default class DefaultRelate extends InputField {
  constructor() {
    super();
    this.state = {
      state: "fine",
      value: null,
      id: null,
      resultData: null,
      resultOpen: false,
      resultArrowIndex: -1,
      loaded: false,
    };
    this.searchInterval = null;
    this.input = React.createRef();
    this.containerRef = React.createRef();
  }
  keyUp(event) {
    const keyCode = event.keyCode;
    if (keyCode === 13 && event.ctrlKey === false) {
      if (this.state.resultOpen == true) {
        const item = this.state.resultData[this.state.resultArrowIndex];
        if (item != undefined) {
          this.selectItem(item, true, false);
          this.setState({ resultOpen: false });
          this.input.current.value = item.name;
        }
      } else {
        this.save();
      }
    } else if (keyCode === 27) {
      this.cancel();
    } else if (keyCode === 40 || keyCode === 38) {
      this.changeArrowIndex(keyCode);
    } else if (keyCode === 8 || keyCode === 46){
      this.checkEmptyValue( false, event)
      this.waitForSearch()
    }else if (keyCode !== 9 && keyCode !== 37 && keyCode !== 39) {
      this.waitForSearch();
    }
  }

  checkEmptyValue(loseFocus, e) {
    if(e && e.relatedTarget && e.relatedTarget.querySelector(".icon-detailSearch")){
      loseFocus = false;
    }
    if(!this.input.current.value){
      this.selectItem({ id: "", name: "" }, true, loseFocus)
    }
  }

  waitForSearch() {
    if (this.searchInterval != null) {
      clearInterval(this.searchInterval);
    }
    var self = this;
    this.searchInterval = setInterval(() => {
      self.search();
      clearInterval(self.searchInterval);
    }, 300);
  }
  changeArrowIndex(keyCode) {
    var resultArrowIndex = this.state.resultArrowIndex;
    if (keyCode === 40) {
      resultArrowIndex += 1;
      this.executeScroll()
    } else if (keyCode === 38) {
      resultArrowIndex -= 1;
      this.executeScroll()
    }

    if (resultArrowIndex < -1) {
      resultArrowIndex = -1;
    }
    if (this.state.resultData != null) {
      if (resultArrowIndex >= this.state.resultData.length) {
        resultArrowIndex = this.state.resultData.length;
      }
    }
    this.setState({ resultArrowIndex });
  }
  search() {
    if (!this.input || !this.input.current || !this.input.current.value) {
      return;
    }
    this.setState({resultOpen: true, loaded: false})
    const value = this.input.current.value;
    const module = this.props.data.def.get("module");
    if (value !== "" || value !== null) {
      const searchData = {
        fields: ["name"],
        getFields: ["id", "name"],
        value,
        module,
      };
      const defaultFilter = this.props.data.def.get("defaultFilter");
      if (defaultFilter) {
        searchData.defaultFilter = defaultFilter.toJS();
      }
      var self = this;
      sAction.quickSearch(searchData, (data) => {
        self.setState({
          resultArrowIndex: -1,
          resultData: data,
          resultOpen: true,
          loaded: true,
        });
      });
    }
  }
  selectItem(item, doUpdate = true, cancelEdit = true) {
    const data = {
      way: this.props.way,
      name: this.props.data.def.get("id_name"),
      fieldName: this.props.data.name,
      vname: this.props.data.def.get("vname"),
      type: "relate",
      value: item,
      cancelEdit,
    };
    this.setState({
      id: item.id,
      name: item.name,
      resultOpen: false,
    });
    this.saveField(data, doUpdate);
    if (this.props.onSelect !== undefined) {
      this.props.onSelect();
    }
  }

  focus() {
    if (this.state.resultData != null) {
      this.setState({
        resultOpen: true,
      });
    }
  }
  openPopupList() {
    var self = this;
    const data = {
      module: this.props.data.def.get("module"),
      selectedActive: false,
    };
    const defaultFilter = this.props.data.def.get("defaultFilter");
    if (defaultFilter) {
      data.defaultFilter = defaultFilter.toJS();
    }
    sAction.openRelatePopup(data, (returnData) => {
      self.selectItem(returnData);
      if (!returnData.popupHidden) {
        sAction.popupHide();
      }
    });
  }
  newRecord() {
    const module = this.props.data.def.get("module");
    sAction.popupDetail({
      module,
      record: "",
      saveCallback: (ret) => {
        const item = {
          id: ret.record,
          name: ret.name,
        };
        const data = {
          way: this.props.way,
          name: this.props.data.def.get("id_name"),
          fieldName: this.props.data.name,
          type: "relate",
          value: item,
          cancelEdit: true,
        };
        this.setState({
          id: item.id,
          name: item.name,
        });
        this.saveField(data, true);
        sAction.unLoad();
      },
      exitCallback: (data) => {},
    });
  }
  getSearchResult() {
    const prefix = this.props.prefix
    const module = this.props.data.def.get("module");
    var searchResult = null;
    if (this.state.resultOpen === true) {
      if (this.state.loaded) {
      var resultList = [];
      if (sAction.hasAccess(module, "edit") && prefix == "view") {
        resultList.push(
          <div
            tabIndex={resultList.length}
            onClick={() => this.newRecord()}
            key={resultList.length}
            className={"quickSearchResultLine newRecord"}
          >
            {sAction.translate("LBL_CREATE_NEW_RECORD")}
          </div>
        );
      }
        //Pokud se nenasly zadne zaznamy vyrendureje se hlaska s nenalazenymi zaznamy
        if (this.state.resultData.length < 1) {
          resultList.push(<div key="noRecords" className="quickSearchNoRecords">
            <span>{sAction.translate("LBL_NO_MATCHES_FOUND")}</span>
          </div>)
        }
      this.state.resultData.forEach((item, index) => {
        var lineClass = "quickSearchResultLine";
        if (item.id === this.props.data.def.get("id_value")) {
          lineClass += " select";
        }

          let refAttr = {}
          if((index - 1) === this.state.resultArrowIndex || (index + 1) === this.state.resultArrowIndex) {
            refAttr = {
              ref: this.containerRef
            }
          }

        if (index === this.state.resultArrowIndex) {
          lineClass += " arrowSelect";
            refAttr = {
              ref: this.containerRef
            }
        }

        resultList.push(
              <div tabIndex={index + 1} onClick={() => this.selectItem(item)} key={index + 1} className={lineClass} {...refAttr}>
            {sAction.decodeHTMLEntities(item.name)}
          </div>
        );
      });
      searchResult = <div className="quickSearchResult">{resultList}</div>;
      } else {
        searchResult = <div className="quickSearchResult"><div className="quickSearchNoRecords">{<Loader/>}</div></div>;
      }
    }

    return searchResult;
  }

  executeScroll = () => {
    if (this.containerRef.current) {
      this.containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }

  }

  onBlur(e) {
    const relTarget = e.relatedTarget;
    if (relTarget == null) {
      this.cancel();
    } else {
      var parent = relTarget.closest(
        "div[data-fieldname='" + this.props.data.name + "']"
      );
      if (parent == null) {
        this.cancel();
      }
    }
  }
  deleteValue = () => {
    this.selectItem({ id: "", name: "" });
  };
  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    let containerClass = "inputEditContainer";

    if(!containerClass || (containerClass && !containerClass?.includes("relateField"))){
      containerClass += " relateField"
    }

    let value = data.value;
    let errorValue =  data.def?.get("error");
    if (newRecord && (value === "" || value === undefined || value === null)) {
      value = "";
    }
    else{
      value = sAction.decodeHTMLEntities(value);
    }

    const searchResult = this.getSearchResult();
    const containerClassField = "inputContainer relateField";

    //Pokud je nasteveny error v definici je potreba vyrenderovat tooltip s errorem pridat classu s vypnutim pointerEventu
    return (
      <div className={containerClass} data-fieldname={data.name}>
        <div className={containerClassField}>
          <InputText
            onKeyDown={(e) => this.onKeyDown(e)}
            autoFocus={true}
            onKeyUp={(event) => this.keyUp(event)}
            onFocus={() => this.focus()}
            onBlur={(e) => this.checkEmptyValue(true, e)}
            myRef={this.input}
            id={data.name}
            defaultValue={value}
            autoComplete="off"
          />
          {searchResult}
        </div>
        {!newRecord && (
          <div className="buttonContainer">
            {errorValue &&
            (
                <div tabIndex="-1" className="warningMessage inputEditButton" style={{pointerEvents: "none"}}>
                  <CustomTooltip content={<div>{errorValue}</div>} direction="bottom">
                    <div className="icon-warning" onMouseDown={this.saveCheck} />
                  </CustomTooltip>
                </div>
            )}
            <div
              tabIndex="1"
              onClick={() => this.openPopupList()}
              className="inputEditButton"
            >
              <div className={"icon-detailSearch"} />
            </div>
            <div
              tabIndex="2"
              onClick={() => this.deleteValue()}
              className=" inputEditButton"
            >
              <div className={"icon-detailCancel"} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
