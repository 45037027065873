import detailDefault from '../../detailDefault';
import React from 'react';

export default class detailAccounts extends detailDefault {
    load(sAction, data) {
        this.updateDetailApiVisibility(sAction, data);
        if (data.prefix === 'conf/popup/data/detailView') { // pokud je tvoreno ze subpanelu
            if (sAction.dataGet('view/module') === 'Accounts') {
                const ParentId = sAction.dataGet('view/id');
                const ParentName = sAction.dataGet('view/name');

                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/parent_name/def/id_value', ParentId);
                sAction.dsAdd('set', data.prefix + '/fields/parent_name/value', ParentName);
                sAction.dsAdd('set', data.prefix + '/changes/fields/parent_id', ParentId);
                sAction.dsProcess();
            }
        }

        this.checkAddressFilled(sAction, data);
        this.checkParent(sAction, data);


        sAction.dsClear();
        sAction.dsAdd('set', data.prefix+'/fields/seeky_activity/def/type', 'HTMLText');
        sAction.dsAdd('set', data.prefix+'/fields/seeky_activity/def/readonly', true);

        const id = sAction.dataGet(data.prefix+'/id');
        if (!id) {
            sAction.dsAdd('set', data.prefix+'/fields/dealer_type/def/required', true);
        }

        sAction.dsProcess();

        if (sAction.deviceType === 'servis') {
            sAction.dataDelete(data.prefix+'/tabs', 1);
            sAction.dataDelete(data.prefix+'/tabs', 1);
        }

        if (!sAction.dataGet(data.prefix + '/fields/billing_address_city/value')) {
            this.generateAccountName(sAction, data.prefix);
        }
    }

    update(sAction, data) {
        switch (data.field) {
            case 'billing_address_country':
                this.updateDetailApiVisibility(sAction, data);
                break;
            case 'acm_address':
                this.getNearAccounts(sAction, data);
                break;
            case 'parent_id':
                this.checkParent(sAction, data);
                this.generateAccountName(sAction, data.prefix);
                break;
            case 'billing_address_city':
                this.generateAccountName(sAction, data.prefix);
                break;
        }

        this.addressesHelper(sAction, data, data);
    }

    checkParent(sAction, data) {
        const parent = sAction.dataGet(data.prefix + '/fields/parent_id/value');
        const parentChanges = sAction.dataGet(data.prefix + '/changes/fields/parent_id');

        sAction.dsClear();
        if (
            (parent || parentChanges ) &&
        parentChanges !== ''
        ) {
            sAction.dsAdd('set', data.prefix + '/fields/ticker_symbol/def/required', false);
        } else {
            sAction.dsAdd('set', data.prefix + '/fields/ticker_symbol/def/required', true);
        }
        sAction.dsProcess();
    }
    addressesHelper(sAction, data, field) {
    // const street = sAction.dataGet(data.prefix + "/fields/billing_address_street/value");
    // const city = sAction.dataGet(data.prefix + "/fields/billing_address_city/value");


        if (field.field === 'acm_address') {
            sAction.dsClear();

            const acmAddressStreet = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/street');
            const acmAddressCity = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/obec');
            const acmAddressPsc = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/psc');
            const acmAddressKraj = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/kraj');
            const acmAddressOkres = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/okres');

            if (acmAddressStreet) {
                sAction.dsAdd('set', data.prefix + '/fields/billing_address_street/value', acmAddressStreet);
                sAction.dsAdd('set', data.prefix + '/changes/fields/billing_address_street', acmAddressStreet);
            }
            if (acmAddressCity) {
                sAction.dsAdd('set', data.prefix + '/fields/billing_address_city/value', acmAddressCity);
                sAction.dsAdd('set', data.prefix + '/changes/fields/billing_address_city', acmAddressCity);
            }
            if (acmAddressPsc) {
                sAction.dsAdd('set', data.prefix + '/fields/billing_address_postalcode/value', acmAddressPsc);
                sAction.dsAdd('set', data.prefix + '/changes/fields/billing_address_postalcode', acmAddressPsc);
            }
            if (acmAddressKraj) {
                sAction.dsAdd('set', data.prefix + '/fields/billing_address_kraj/value', acmAddressKraj);
                sAction.dsAdd('set', data.prefix + '/changes/fields/billing_address_kraj', acmAddressKraj);
            }
            if (acmAddressOkres) {
                sAction.dsAdd('set', data.prefix + '/fields/billing_address_state/value', acmAddressOkres);
                sAction.dsAdd('set', data.prefix + '/changes/fields/billing_address_state', acmAddressOkres);
            }

            sAction.dsProcess();
        }
    }

    checkAddressFilled(sAction, data) {
        const gpsx = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/gpsX');
        const gpsy = sAction.dataGet(data.prefix + '/fields/acm_address/def/item/gpsY');

        if (gpsx && gpsy) {
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/acm_address/def/required', false);
            sAction.dsProcess();
        }
    }

    // fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
    updateDetailApiVisibility(sAction, data) {
        let address;
        if (data.value) {
            address = data.value;
        } else {
            address = sAction.dataGet(data.prefix + '/fields/billing_address_country/value');
        }

        const updateFieldApiValue = (fieldName, fieldValue) => {
            if (sAction.dataGet(data.prefix + '/fields/'+fieldName) !== undefined) {
                sAction.dsAdd('set', data.prefix + '/fields/'+fieldName+'/def/showDetailApi', fieldValue);
            }
        };

        sAction.dsClear();
        if (!address || address === 'CZECH REPUBLIC') {
            // zobrazi ikony
            updateFieldApiValue('name', 'ApiName');
            updateFieldApiValue('account_name', 'ApiName');
            updateFieldApiValue('ticker_symbol', 'ApiICO');
            updateFieldApiValue('sic_code', 'ApiDIC');
            updateFieldApiValue('billing_address_street', 'ApiStreet');
            updateFieldApiValue('shipping_address_street', 'ApiStreet');
            updateFieldApiValue('billing_address_postalcode', 'ApiPSC');
            updateFieldApiValue('shipping_address_postalcode', 'ApiPSC');
        } else if (address === 'SLOVAKIA') {
            // skryje ikony
            updateFieldApiValue('name', 'ApiNameSK');
            updateFieldApiValue('account_name', 'ApiNameSK');
            updateFieldApiValue('ticker_symbol', 'ApiICOSlovakia');
            updateFieldApiValue('sic_code', '');
            updateFieldApiValue('billing_address_street', 'ApiStreet');
            updateFieldApiValue('shipping_address_street', 'ApiStreet');
            updateFieldApiValue('billing_address_postalcode', 'ApiPSC');
            updateFieldApiValue('shipping_address_postalcode', 'ApiPSC');
        } else {
            // skryje ikony
            updateFieldApiValue('name', 'ApiNameOthers');
            updateFieldApiValue('account_name', '');
            updateFieldApiValue('ticker_symbol', '');
            updateFieldApiValue('sic_code', '');
            updateFieldApiValue('billing_address_street', '');
            updateFieldApiValue('shipping_address_street', '');
            updateFieldApiValue('billing_address_postalcode', '');
            updateFieldApiValue('shipping_address_postalcode', '');
        }


        const tabs = sAction.dataGet(data.prefix + '/tabs');
        if (tabs) {
            const finstatIndex = tabs.findIndex((i) => i.name === 'LBL_DETAILVIEW_PANEL_FINSTAT');
            if (finstatIndex !== -1) {
                if (address !== 'SLOVAKIA') {
                    sAction.dsAdd('set', data.prefix + '/tabs/' + finstatIndex + '/hidden', true);
                } else {
                    sAction.dsAdd('set', data.prefix + '/tabs/' + finstatIndex + '/hidden', false);
                }
            }
        }
        sAction.dsProcess();
    }

    getNearAccounts(sAction, data) {
        const item = sAction.dataGet(data.prefix + '/fields/acm_address/def/item');
        const id = sAction.dataGet(data.prefix + '/id');
        const address = {
            x: item.get('gpsX'),
            y: item.get('gpsY'),
            cast_obce: item.get('cast_obce'),
            kraj: item.get('kraj'),
            obec: item.get('obec'),
            okres: item.get('okres'),
            psc: item.get('psc'),
            id: id,
        };

        sAction.rest.post('getNearAddresses', address, (returnData) => {
            if (returnData.status) {
                if (returnData.message.data.length > 0) {
                    let message = sAction.translate('LBL_NEAR_ACCOUNT_FOUND', 'Accounts');
                    const accounts = [];
                    returnData.message.data.forEachObject((value) => {
                        accounts.push(
                            (
                                <div style={{margin: 'auto'}}>
                                    <a href={'#detail/Accounts/' + value.id}
                                        target='_blank'
                                        rel="noreferrer">
                                        {value.name} | IČ: {value.ticker_symbol}
                                    </a>
                                </div>
                            ),
                        );
                    });
                    message = (
                        <div style={{margin: 'auto'}}>
                            <div>
                                {message}
                            </div>
                            <br />
                            {accounts}
                        </div>
                    );
                    const data = {
                        // Potvrzení
                        header: sAction.translate('LBL_CONFIRM'),
                        buttons: [
                            // Pokračovat
                            {label: sAction.translate('LBL_CONTINUE', 'Home'), callback: () => sAction.popupHide()},
                        ],
                    };

                    sAction.popup(message, data);
                }
            } else {
                sAction.error(sAction.translate(returnData.errorMessage.text));
            }
        }, false);
    }

    generateAccountName(sAction, prefix) {
        if (sAction.dataGet(prefix + '/fields/rel_type/value') === 'parent' ||
           !sAction.dataGet(prefix + '/fields/parent_name/def/id_value')) {
            return;
        }
        const parentAccount = sAction.dataGet(prefix + '/fields/parent_name/value');
        const city = sAction.dataGet(prefix + '/fields/billing_address_city/value');
        let newAccountName = '';

        if (parentAccount && !city) {
            newAccountName = `${parentAccount} - `;
        } else if (!parentAccount && city) {
            newAccountName = ` - ${city}`;
        } else if (city && parentAccount) {
            newAccountName = `${parentAccount} - ${city}`;
        }

        if (newAccountName) {
            sAction.dataSet(prefix + '/fields/name/value', newAccountName);
            sAction.dataSet(prefix + '/changes/fields/name', newAccountName);
        }
    }
}
