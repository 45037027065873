export default async function saveAcmAddressField(item,field,prefix,way) {
    const street = this.getStreetlabelAcmAddress(item)
    const data = {
        way: way,
        name: field.name,
        fieldName: field.name,
        value: street,
        cancelEdit: true,
    };

    const searchPsc = item.psc.replace(' ', '');

    this.rest.post("detailapi/checkpsc", { psc: searchPsc }, (responseData) => {
        item.kraj = responseData._address_kraj ??  item.kraj ?? '';
        item.okres = responseData._address_state ??  item.okres ?? '';


        var name = field.name
        var customPrefix = "";
        if (name.endsWith("_c")) {
            customPrefix = "_c";
            name = name.substring(0, name.length - 2);
        }

        this.dsClear();
        this.dsAdd("set", prefix + "/changes/fields/" + name + "_cast_obce" + customPrefix, item.cast_obce);
        this.dsAdd("set", prefix + "/changes/fields/" + name + "_street" + customPrefix, street);
        this.dsAdd("set", prefix + "/changes/fields/" + name + "_kraj" + customPrefix, item.kraj);
        this.dsAdd("set", prefix + "/changes/fields/" + name + "_okres" + customPrefix, item.okres);
        this.dsAdd("set", prefix + "/changes/fields/" + name + "_obec" + customPrefix, item.obec);
        this.dsAdd("set", prefix + "/changes/fields/" + name + "_psc" + customPrefix, item.psc);
        this.dsAdd("set", prefix + "/changes/fields/" + name + "_gpsx" + customPrefix, item.x);
        this.dsAdd("set", prefix + "/changes/fields/" + name + "_gpsy" + customPrefix, item.y);

        this.dsAdd("set", prefix + "/fields/" + field.name + "/def/item/cast_obce", item.cast_obce)
        this.dsAdd("set", prefix + "/fields/" + field.name + "/def/item/street", street)
        this.dsAdd("set", prefix + "/fields/" + field.name + "/def/item/kraj", item.kraj)
        this.dsAdd("set", prefix + "/fields/" + field.name + "/def/item/okres", item.okres)
        this.dsAdd("set", prefix + "/fields/" + field.name + "/def/item/obec", item.obec)
        this.dsAdd("set", prefix + "/fields/" + field.name + "/def/item/psc", item.psc)
        this.dsAdd("set", prefix + "/fields/" + field.name + "/def/item/gpsX", item.x)
        this.dsAdd("set", prefix + "/fields/" + field.name + "/def/item/gpsY", item.y)

        //obnovovani zaznamu
        this.dsAdd("set", prefix + "/changes/lsValues/" + prefix + "/fields/" + field.name + "/def/item/cast_obce", item.cast_obce)
        this.dsAdd("set", prefix + "/changes/lsValues/" + prefix + "/fields/" + field.name + "/def/item/street", street)
        this.dsAdd("set", prefix + "/changes/lsValues/" + prefix + "/fields/" + field.name + "/def/item/kraj", item.kraj)
        this.dsAdd("set", prefix + "/changes/lsValues/" + prefix + "/fields/" + field.name + "/def/item/okres", item.okres)
        this.dsAdd("set", prefix + "/changes/lsValues/" + prefix + "/fields/" + field.name + "/def/item/obec", item.obec)
        this.dsAdd("set", prefix + "/changes/lsValues/" + prefix + "/fields/" + field.name + "/def/item/psc", item.psc)
        this.dsAdd("set", prefix + "/changes/lsValues/" + prefix + "/fields/" + field.name + "/def/item/gpsX", item.x)
        this.dsAdd("set", prefix + "/changes/lsValues/" + prefix + "/fields/" + field.name + "/def/item/gpsY", item.y)

        this.dsProcess();

        this.saveField(data, true);

        if(this.dataGet('conf/popup/content') === 'openStreetMap' || this.dataGet('conf/popup2/content') === 'openStreetMap'){
            this.popupHide()
        }
    }, false);
}

