import React, {useState, useCallback, useEffect} from 'react';
import sAction from 'sAction';
import Loader from '../loader';
import PropTypes from 'prop-types';
import Button from '../formElements/Button';
import CheckBox from '../formElements/CheckBox';
import Relate from '../formElements/Relate';
import InputText from '../formElements/InputText';

const EshopSettings = () => {
    // todo! eshopConfig = delivery on rest

    const [state, setState] = useState(null);
    const [usageList, setUsageList] = useState(null);
    const [loading, setLoading] = useState();

    const fetchData = useCallback(() => {
        setLoading(true);
        sAction.rest.get('admin/getEshopConfig', (response) => {
            setLoading(false);
            if (response.status && response.data) {
                setState(response.data?.eshopConfig);
                setUsageList(response.data?.usageList);
            }
        });
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = useCallback((e, name, index, usage = null) => {
        if (!state[index].weightRange) {
            state[index].weightRange = [0,0];
            setState([...state]);
        }

        switch (name) {
            case 'productId':
                state[index].productId = e.id;
                state[index].name = e.name;

                break;
            case 'usage':
                const usageIndex = state[index].usages.indexOf(usage);

                if (e === true) {
                    if (usageIndex === -1) {
                        state[index].usages.push(usage);
                    }
                } else {
                    if (usageIndex > -1) {
                        state[index].usages.splice(usageIndex, 1);
                    }
                }

                break;
            case 'weightRangeFrom':
                state[index].weightRange[0] = +e.target.value;

                break;
            case 'weightRangeTo':
                state[index].weightRange[1] = +e.target.value;

                break;
        }

        setState([...state]);
    }, [state]);

    const handleSubmitOAuth = useCallback(() => {
        setLoading(true);
        sAction.rest.post('admin/saveEshopConfig', state, (response) => {
            setLoading(false);
            if (response.status) {
                fetchData();
            }
        });
    }, [state]);

    if (loading || !usageList || !state) {
        return <Loader />;
    }

    return (
        <div className="customReportContainer">
            <div className="customReport adminEshopSettings">
                <div className="customReportHeader adminEmailOAuthHeaderFlex">
                    <div className="headerIconEshopSettings">
                        <i className="iconfa-eshopSettingsAdmin"></i>
                    </div>
                    <div className="headerContentEshopSettings">
                        <h2 className="adminEshopSettingsHeaderH2">
                            {sAction.app_strings['LBL_ESHOP_SETTINGS']}
                        </h2>

                        <Button
                            className="btn btn-primary eshopSettingsSaveButton"
                            onClick={handleSubmitOAuth}
                        >
                            <span className="iconfa-save"></span>
                            {sAction.translate('LBL_EMAIL_SETTINGS_OAUTH_SAVE')}
                        </Button>
                    </div>
                </div>

                <div className="customReportContent">
                    <h4>{sAction.translate('LBL_ESHOP_SETTINGS_DELIVERY_OPTIONS')}</h4>

                    <div className='eshopSettingsList'>
                        {state && state.length > 0 && state.map((item, index) => {
                            return (
                                <div key={index} className="eshopSettingsItem">
                                    <div className='eshopSettingsItemLeft'>
                                        <div className='DetailViewValue eshopSettingsItemRelate'>
                                            <Relate
                                                newRecord={false}
                                                data={{
                                                    id: item.productId,
                                                    value: item.name,
                                                    label: item.name,
                                                }}
                                                buttons={[]}
                                                module={'ProductTemplates'}
                                                callback={(e) => handleChange(e, 'productId', index)}
                                                defaultFilter={{
                                                // filter na [90] služby
                                                    ProductTemplates: {
                                                        field: 'products',
                                                        type: 'eq',
                                                        value: '90',
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div className='eshopSettingsItemDetails'>
                                            {item.price && item.price !== 0 && (
                                                <div className='settingsItemValueField'>
                                                    <span className='settingsItemLabel'>
                                                        {sAction.translate('LBL_ESHOP_SETTINGS_PRICE')}
                                                    </span>
                                                    <span className='settingsItemValue'>
                                                        {parseFloat(item.price).toFixed(2)} Kč
                                                    </span>
                                                </div>
                                            )}
                                            {item.erpId && (
                                                <div className='settingsItemValueField'>
                                                    <span className='settingsItemLabel'>
                                                        {sAction.translate('LBL_ESHOP_SETTINGS_ERP_ID')}
                                                    </span>
                                                    <span className='settingsItemValue'>
                                                        {item.erpId}
                                                    </span>
                                                </div>
                                            )}
                                            {item.description && (
                                                <div className='settingsItemValueField'>
                                                    <span className='settingsItemLabel'>
                                                        {sAction.translate('LBL_ESHOP_SETTINGS_DESCRIPTION')}
                                                    </span>
                                                    <span className='settingsItemValue'>
                                                        {item.description}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        <div className='eshopSettingsItemWeight'>
                                            <div className="eshopSettingsItemWeightInput">
                                                <p className="controlLabel">
                                                    {sAction.translate('LBL_ESHOP_SETTINGS_WEIGHT_FROM')}
                                                </p>
                                                <InputText
                                                    defaultValue={item.weightRange ? item.weightRange[0] : 0}
                                                    onChange={(e) => handleChange(e, 'weightRangeFrom', index)}
                                                />
                                            </div>
                                            <div className="eshopSettingsItemWeightInput">
                                                <p className="controlLabel">
                                                    {sAction.translate('LBL_ESHOP_SETTINGS_WEIGHT_TO')}
                                                </p>
                                                <InputText
                                                    defaultValue={item.weightRange ? item.weightRange[1] : 0}
                                                    onChange={(e) => handleChange(e, 'weightRangeTo', index)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='eshopSettingsItemRight'>
                                        <div className="eshopSettingsItemUsages">
                                            {Object.keys(usageList).map((usage, usageIndex) => (
                                                <div className="checkboxLine"
                                                    title={usageList[usage] + ` [${usage}]`}
                                                    key={usageIndex}>
                                                    <CheckBox
                                                        id="emailUseTLS"
                                                        defaultValue={item.usages.indexOf(usage) > -1 ? 1 : 0}
                                                        onChange={(e) => handleChange(e, 'usage', index, usage)}
                                                    />
                                                    <p className="controlLabel">
                                                        {usageList[usage]}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='eshopSettingsItemButtons'>
                                        <div
                                            className="eshopSettingsDeleteButton"
                                            onClick={() => {
                                                const newState = [...state];
                                                newState.splice(index, 1);
                                                setState(newState);
                                            }}
                                        >
                                            <span className="iconfa-remove"></span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {/* add button */}
                        <Button
                            className="btn btn-primary eshopSettingsAddButton"
                            onClick={() => {
                                setState([...state, {
                                    productId: '',
                                    name: '',
                                    price: null,
                                    usages: [],
                                }]);
                            }}
                        >
                            <span className="iconfa-plus"></span>
                        </Button>

                    </div>


                </div>
            </div>
        </div>
    );
};

EshopSettings.propTypes = {
    resetButtons: PropTypes.func.isRequired,
    addButton: PropTypes.func.isRequired,
};

export default EshopSettings;
