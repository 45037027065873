export default function setSearchMainModule(module){
    this.rest.get("search/" + module, (data) => {
            this.dsClear();
            this.dsAdd("set", "reportWindow/savedSearch", []);
            data.data.forEach((search) => {
                if (search.category == "report") {
                    this.dsAdd("add", "reportWindow/savedSearch", search);
                }
            });
            this.dsProcess();
        },
        false
    );
}