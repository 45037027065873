/**
 * Kontrola na vyplnenost povinnych fieldu - from base record save modified (requiredFieldsCheck)
 * vraci true/false podle toho, jestli byly vyplneny vsechny povinne polozky...
 * @param {string} prefix
 * @return {boolean}
 */
export default function requiredFieldsCheckActual(prefix) {
    const fields = this.dataGet(prefix + '/fields');
    let isOK = true;

    // ziskani poli ve skrytych tabech pro nasledene ignorovani pri overeni povinnych poli
    const ignoredFields = ['id'];
    const visibleFields = [];
    const tabs = this.dataGet(prefix + '/tabs');
    tabs.forEach((tab, index) => {
        tab.rows.forEach((row, index) => {
            row.forEach((line, index) => {
                if (tab.hidden) {
                    ignoredFields.push(line);
                } else {
                    visibleFields.push(line);
                }
            });
        });
    });

    const hiddenFields = this.dataGet(prefix + '/hiddenFields');
    hiddenFields.forEach((field) => {
        ignoredFields.push(field);
    });

    fields.valueSeq().forEach((data) => {
        if (!data.def) return;
        if (ignoredFields.includes(data.name)) {
            return;
        }
        if (!visibleFields.includes(data.name)) {
            return;
        }
        if (data.def.get('required') === true &&
            (data.value === '' || data.value === null)
        ) {
            console.log('requiredFieldsCheckActual -> data.name', data.name);
            isOK = false;
        } else if (
            data.def.get('required') === true &&
            (data.value != '' || data.value != undefined) &&
            data.customClass == 'error'
        ) {
            console.log('requiredFieldsCheckActual -> data.name', data.name);
            isOK = false;
        }
    });

    return isOK;
}
