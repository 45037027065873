export default function accCreateQuote(data) {
    this.detailPredefinedFields = [];

    // nazev firmy
    const parentId = this.dataGet(`${data.prefix}/fields/parent_id/value`);
    const parentName = this.dataGet(`${data.prefix}/fields/parent_name/value`);

    // pokud je firma pobocka billing_account_id = parent_id a accounts_quotes_1accounts_ida = id
    if (parentId) {
        this.detailPredefinedFields.push({
            type: 'relate_simple',
            fieldName: 'billing_account_name',
            name: 'billing_account_id',
            value: {'id': parentId, 'name': parentName},
        });
        this.detailPredefinedFields.push({
            type: 'relate_simple',
            fieldName: 'accounts_quotes_1_name',
            name: 'accounts_quotes_1',
            value: {'id': data.id, 'name': data.name},
        });
        this.detailPredefinedFields.push({
            type: 'field',
            name: 'accounts_quotes_1accounts_ida',
            value: data.id,
        });
    } else { // jinak billing_account_id = id
        this.detailPredefinedFields.push({
            type: 'relate_simple',
            fieldName: 'billing_account_name',
            name: 'billing_account_id',
            value: {'id': data.id, 'name': data.name},
        });
    }

    // faze Nabidky => nova
    this.detailPredefinedFields.push({
        type: 'field',
        name: 'quote_stage',
        value: 'New',
    });
    this.detailNewRecord({
        module: 'Quotes',
        way: data.prefix,
    });
}
