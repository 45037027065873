import React, {Component} from 'react'

import InputField from '../inputFieldClass'
import sAction from "sAction";

export default class Phone extends InputField {
    constructor() {
        super();
        this.state = {
            state: "fine",
            reg: /^[+]?[0-9 -]{9,}$/,
            formatted: false,
        };
    }

    getCurrentValue() {
        if (!this.props.data.value) {
            return '+420 ';
        } else {
            return this.props.data.value;
        }
    }

    saveField(data) {
        data.value = this.formatPhoneNumber(data.value)
        sAction.saveField(data);
    }

    /**
     * Podle dokumentace string.includes v switch case by se nemel pouzivat (abuse of case) takze resim pres else if
     * @param value
     * @returns {string|*}
     */
    formatPhoneNumber(value) {
        let formattedValue = ''
        if (value.includes('+420') || value.includes('+421')) {
            formattedValue = this.czechFormat(value)
        }

        if (formattedValue) {
            return formattedValue
        }
        return value

    }

    /**
     * odstrani mezery pomlcky a zformatuje v tvaru xxx xxx
     * @param phoneNumber
     * @returns {string}
     */
    czechFormat(phoneNumber) {
        let formattedPhone = phoneNumber.substring(1).replace(/-|\s/g,'');

        return '+' + formattedPhone.replace(/(.{3})/g,"$1 ")
    }
}
