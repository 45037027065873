import React, {useState, useEffect} from 'react';
import sAction from 'sAction';
import AdminIframe from './AdminIframe';
import ErrorLogs from '../Errors/ErrorLogs/errorLogs';
import ErrorLogDetail from '../Errors/ErrorLogs/errorLogDetail';
import EmailSettingsOAuth from './EmailSettingsOAuth';
import EshopSettings from './EshopSettings';
import AdminHeaderButtons from './AdminHeaderButtons';
import Button from '../formElements/Button';


function Admin(props) {
    const initialState = [{onClick: () => window.history.back(),
        className: 'actionPanelButtonIcon icon-undo',
        value: sAction.app_strings['LBL_RETURN_BACK'],
    }];
    const data = props.data;
    const hash = window.location.hash.replace('#', '').split('?')[0];
    const [adminButtons, setAdminButtons] = useState(initialState);
    const uri = hash.split('/');
    const name = uri[2];

    // prida button do AdminHeaderButtons
    const addButton = (onClick, value, className) => {
        setAdminButtons( (prev) => [...prev, {onClick: onClick, value: value, className: className}]);
    };

    // použiť resetButtons vždy pri componentUnmount inak ostanú vysieť
    const resetButtons = () => {
        setAdminButtons(initialState);
    };

    const renderSwitch= (uri)=> {
        switch (uri) {
            case 'error_logs':
                return <ErrorLogs resetButtons={resetButtons} addButton={addButton} data={sAction.dataGet('menu')} />;
            case 'error_log_detail':
                return <ErrorLogDetail resetButtons={resetButtons} addButton={addButton} data={sAction.dataGet('menu')} />;
            case 'EmailSettingsOAuth':
                return <EmailSettingsOAuth resetButtons={resetButtons} addButton={addButton} data={sAction.dataGet('menu')} />;
            case 'EshopSettings':
                return <EshopSettings resetButtons={resetButtons} addButton={addButton} data={sAction.dataGet('menu')} />;
            default:
                return <AdminIframe name={name} way={'view'} data={data.customData} />;
        }
    };


    let content = null;
    if (uri[1] == 'custom') {
        content = (
            <div className="reportViewContainer">
                <AdminHeaderButtons adminButtons={adminButtons}/>
                { renderSwitch(uri[2])}
            </div>);
    } else {
        content = (

            <div className="pageContainer DetailViewContainer">
                <div className="detailViewContainer">
                    <div className="detailCard">
                        <div className="header">
                            <div className="title">
                                <span className="detailViewHeaderIcon icon-admin" />
                                <span className="detailViewName">
                                    {sAction.translate('LBL_MODULE_NAME', 'Administration')}
                                </span>
                            </div>

                            <div
                                className="appVersion"
                                onClick={() => sAction.showChannelLog()}
                            >
                                <b
                                    title={
                                        sAction.translate('LBL_BACKEND_VERSION') +
                  props.back_version
                                    }
                                >
                B: {props.back_version}
                                </b>{' '}
              |
                                <b
                                    title={
                                        sAction.translate('LBL_FRONTEND_VERSION') +
                  props.front_version
                                    }
                                >
                F: {props.front_version}

                                </b>
                            </div>

                        </div>


                        <div className="detailView admin">
                            {// sekce
                                props.data.sections.map((s) => {
                                    return (
                                        <div className="adminSection" key={s.title}>
                                            <div className="admin__section__title">
                                                <div className={'icon-' + s.icon + ' sectionIcon'} />
                                                {sAction.translate(s.title, 'Administration')}
                                            </div>
                                            <div className="sectionContent">
                                                <div className="">{sAction.translate(s.description, 'Administration')}</div>
                                                <table className="w100">
                                                    <tbody>
                                                        {// sloupce
                                                            s.rows.map((r, i) => {
                                                                return (
                                                                    <tr
                                                                        key={r.title}
                                                                        onClick={() =>
                                                                            (window.location.href = r.link)
                                                                        }
                                                                    >
                                                                        <td className="icon">
                                                                            <span className={'icon-EditField'} />
                                                                        </td>
                                                                        <td className="name">
                                                                            <a href={r.link}>{sAction.translate(r.title, 'Administration')}</a>
                                                                        </td>
                                                                        <td className="description">
                                                                            {sAction.translate(r.description, 'Administration')}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return content;
}
export default Admin;
