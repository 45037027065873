import React, {useState, useEffect} from 'react';
import sAction from 'sAction';
import Loader from '../../loader';
import PropTypes from 'prop-types';

const ErrorLogDetail = (props) => {
    const [logData, setLogData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorLogId, setErrorLogId] = useState(null);

    useEffect(() => {
        const urlSplit = window.location.hash.split('/');
        const logId = urlSplit[urlSplit.length - 1];
        setErrorLogId(logId);

        sAction.rest.post('getErrorLogDetail', {logId: logId}, (returnData) => {
            if (!returnData.status) {
                sAction.error(returnData.message.text);
                return;
            }
            setLogData(processLogData(returnData.message.data));
            setLoading(false);
        }, false);
    }, []);

    const processLogData = (logData) => {
        try {
            logData['error_log'] = JSON.parse(logData['error_log']);
        } catch (e) {
            console.log('[ERROR] json parse error_log', logData['error_log'].substring( 0, 80) + '...');
        }
        return logData;
    };

    if (loading) {
        return <Loader />;
    }

    // ignore request for error data in network stack log (see rest.js)
    let typeClass = 'info_tag';
    switch( logData?.type){
        case 'error':
            typeClass = 'error_tag';
            break;
        case 'slow-loading':
        case 'warning':
            typeClass = 'warning_tag';
    }

    return (
        <div className='customReportContainer'>
            <div className='customReport adminErrorLogs'>
                <div className='customReportHeader errorLogsAdminHeader'>
                    <div className='headerIconErrorLogs'>
                        <i className='iconfas-error_logs'></i>
                    </div>
                    <h2 className='adminErrorLogsHeader'>{sAction.app_strings['LBL_ERROR_LOG_DETAIL']} | {errorLogId}</h2>
                </div>

                <div className='customReportContent'>
                    <div className='errorLogDetail'>
                        
                        <p className={'lineTag ' + typeClass} >{logData?.type ? logData?.type : 'log'}</p>

                        <div className='errorLogDetailItem'>
                            <pre className={'jsonOutput open'}>{JSON.stringify(logData, undefined, 4)}</pre>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

ErrorLogDetail.propTypes = {
    resetButtons: PropTypes.func.isRequired,
    addButton: PropTypes.func.isRequired,
};


export default ErrorLogDetail;
