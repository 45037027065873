import React from "react";
import MaterialDefault from "./MaterialDefault";
import sAction from "sAction";
import {
    Select as MaterialSelect,
    InputLabel,
    MenuItem,
    FormControl,
    ListSubheader,
    TextField,
} from "@material-ui/core";

export default class Select extends MaterialDefault {
  constructor(props) {
    super(props);
    var value = "";
    if (this.props.defaultValue != undefined) {
      value = this.props.defaultValue;
    }

    var open = false;
    if (this.props.open != undefined) {
      open = this.props.open;
    }
    this.state = {
      value: value,
      focus: false,
      openOnFocus: true,
      open: open,
      options: this.sortOptions(this.props.options),
      searchText: '',
    };
    this.options = this.sortOptions(this.props.options);
  }

  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      this.setState({options: this.sortOptions(this.props.options)});
    }
  }

  /**
   *
   * @param {Object} options
   * @returns {Array}
   */
  sortOptions(options) {
    const newOptions = options.sort((a, b) => a.label - b.label);
    return newOptions;
  }

  /**
   *
   * @param {*} value
   */
  searchText(value) {
    this.setState({searchText: value});
    let options = this.sortOptions(this.props.options);

    options = options.filter((option) => {
      if (option.label.toLowerCase().includes(value.toLowerCase())) {
        return option;
      }
    });

    if (options[0]?.value !== 'acm_add_new') {
      options = [this.options[0]].concat(options);
    }
    this.setState({options: options});
  }


  onChange(e) {
    this.setState({
      value: e.target.value,
      open: false
    });
    if (this.props.onChange != undefined) {
      this.props.onChange(e);
    }
  }
  onClose(e) {
    this.setState({
      open: false,
      searchText: '',
    });
    setTimeout(() => { }, 100);
    if (this.props.onClose != undefined) {
      this.props.onClose(e);
    }
  }
  onOpen(e) {
    this.setState({ open: true });
    if (this.props.onOpen != undefined) {
      this.props.onOpen(e);
    }
  }
  onBlur(e) {
    if (this.props.onBlur != undefined) {
      this.props.onBlur(e);
    }
  }
  onKeyDown(e) {
    if (e.keyCode === 13 && this.state.open === false) {
      this.setState({ open: true })
    }

    if (this.props.onKeyDown != undefined) {
      this.props.onKeyDown(e);
    }
  }
  onFocus(e) {
    if (this.props.onFocus !== undefined) {
      this.props.onFocus(e);
    }
  }
  /**
   *
   * @returns {*}
   */
  renderOptionLabelByValue() {
    const renderOption = this.state.options.find((option) => {
      if (option.value === (this.state.value || this.props.value)) {
        return option;
      }
    });

    return renderOption?.label;
  }

  render() {
    var labelRender = null;
    if (this.props.label != undefined) {
      labelRender = (
        <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
      );
    }
    // const options = this.props.options;

    const colors = this.props.colors;

    var optionsRender = [];

    this.state.options.filter(option => option != undefined).forEach((value, key) => {
      let isDisabled = false;

      if (value.disabled) {
        isDisabled = true;
      }

      if (typeof (value.label) === 'string') {
        value.label = sAction.decodeHTMLEntities(value.label);
      }

      if (value.type == "header") {
        let style = null;
        if (colors && colors[value.value]) {
          style = {backgroundColor: colors[value.value], color: colors[value.value]};
        }
        optionsRender.push(
          <MenuItem key={key} disabled={true} className="acmSelectHeader">
            {style ? (
              <span className='colorList listFilter' style={style}> </span>
            ) : null}
            {value.label} {sAction.getStorage("debug") && " [" + value.value + "]"}
          </MenuItem>
        );
      } else {
        let style = null;
        if (colors && colors[value.value]) {
          style = {backgroundColor: colors[value.value], color: colors[value.value]};
        }
        optionsRender.push(
          <MenuItem key={key} disabled={isDisabled} value={value.value}
                    className={value.type == "button" ? "selectButton" : ""}>
            {style ? (
              <span className='colorList listFilter' style={style}> </span>
            ) : null}
            {value.label}{sAction.getStorage("debug") && " [" + value.value + "]"}
          </MenuItem>
        );
      }
      // }
    });


    var className = "acmSelectContainer";
    if (this.state.focus == true) {
      className = "acmSelectContainer focus";
    }
    if (this.props.error == true) {
      className += " error";
    }
    // const attr = this.createAttr("acmSelect");

    var value = this.state.value;
    if (this.props.value != undefined) {
      value = this.props.value;
    }

    const isOptionsListLong = (this.props.options.length >= 100);
    optionsRender = optionsRender.slice(0, 199);

    return (
      <FormControl
        className={
          this.props.containerClassName != undefined
            ? className + " " + this.props.containerClassName
            : className
        }
        disabled={this.props.disabled}
        onKeyDown={e => this.onKeyDown(e)}
        autoFocus={this.props.autoFocus}
        style={this.props.containerStyle ? this.props.containerStyle : null}
      >
        {labelRender}
        <MaterialSelect
          native={optionsRender.length > 200 ? true : false}
          className="acmSelect"
          value={value}
          id={this.props.id}
          inputProps={{ id: this.props.id }}
          onChange={e => this.onChange(e)}
          onOpen={e => this.onOpen(e)}
          onClose={e => this.onClose(e)}
          onBlur={e => this.onBlur(e)}
          onKeyDown={e => this.onKeyDown(e)}
          onFocus={e => this.onFocus(e)}
          inputRef={this.props.myRef}
          open={this.state.open}
          autoFocus={this.props.autoFocus}
          name={this.props.name}
          style={this.props.style ? this.props.style : null}
          autoWidth={this.props.autoWidth}
          MenuProps={this.props.menuProps}
          renderValue={() => this.renderOptionLabelByValue()}
        >
          {isOptionsListLong && (
            <ListSubheader>
                <TextField
                    size="small"
                    autoFocus={this.props.autoFocus}
                    placeholder={sAction.translate('LBL_TYPE_TO_SEARCH')}
                    fullWidth
                    onBlur={(e) => this.onBlur(e)}
                    // InputProps={{
                    //     startAdornment: (
                    //         <InputAdornment position="start">
                    //             <SearchIcon />
                    //         </InputAdornment>
                    //     ),
                    // }}
                    onChange={(e) => this.searchText(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key !== 'Escape') {
                            // Prevents autoselecting item while typing (default Select behaviour)
                            e.stopPropagation();
                        }
                    }}
                />
            </ListSubheader>
          )}
          {optionsRender}

        </MaterialSelect>
      </FormControl>
    );
  }
}
