import React, { Component } from 'react'
import InputField from '../inputFieldClass'
import sAction from "../../../action";
import InputText from "../../formElements/InputText";

export default class DefaultFloat extends InputField {

  constructor(){
    super();
    this.dec_sep = sAction.userPreferences.dec_sep || ",";
    this.precision = sAction.userPreferences.currency_significant_digits || 2;
    this.state = {
      state: "fine",
      reg: /^-?\d*\.?\d+$/,
    };
  }

  getValue() {
    let value = this.input.current.value;
    if (typeof value === "string") {
      value = value.replace(this.dec_sep, ".");
    }
    return value;
  }

  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    let displayType = data.def?.get('displayType');
    let defPrcision = data.def?.get('precision');
    const ok = this.state.ok;
    // var inputClass = "form-control form-control-sm";
    var containerClass = "inputEditContainer";
    var error = false;
    if (ok === false) {
      //  inputClass += " wrongInput";
      error = true;
    }

    let value = data.value;
    if (!value) {
      value = '0';
    }
    if (value) {
      value = parseFloat(value);
      value = value.toFixed(displayType?defPrcision:this.precision);
      value = value.toString();
    }
    if (typeof value === "string") {
      value = value.replace(".", this.dec_sep);
    }
    if (newRecord && (value == "" || value == undefined)) {
      value = "";
    }
    if (displayType){
      value = sAction.formatNumberToHours(value, displayType);
      this.state.reg = undefined;
    }

    return (
      <div className={containerClass} data-fieldname={data.name}>
        <div className="inputContainer">
          <InputText
            autoFocus={true}
            onKeyUp={e => this.keyUpCall(e)}
            onKeyDown={e => this.onKeyDown(e)}
            myRef={this.input}
            onBlur={e => this.onBlur(e)}
            error={error}
            defaultValue={value}
            id={data.name}
            name={data.name}
          />
        </div>
        {!newRecord && (
          <div className="buttonContainer">
            <div
              tabIndex="2"
              onClick={() => this.save()}
              className="inputEditButton"
            >
              <div className={"icon-detailConfrim"} />
            </div>
            <div
              tabIndex="3"
              onClick={() => this.cancel()}
              className=" inputEditButton"
            >
              <div className={"icon-detailCancel"} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
