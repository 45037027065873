import React from 'react';

export default function remindChecklist() {
    const message = (<p className='remindChecklistPopupMessage'>
        <span className="iconfas-warning" /> {this.translate('LBL_REMIND_CHECKLIST')}
    </p>);

    this.confrim(message, () => {
        this.href('./#detail/acm_service_checklist');
        this.popupHide();
    });
}
