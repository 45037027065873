import React from "react";
import sAction from "sAction";
import PureComponent from "../pure";
import Button from "../../components/formElements/Button";

export default class ListViewRemoveFilters extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            disabledButton: props.data.defaultFilter.size <= 0
        }
    }

    removeFilters() {
        sAction.dataSet(this.props.prefix + "/defaultFilter", {})
        this.setState({disabledButton: true})
        sAction.reloadList(this.props.data.offset, this.props.data.page, this.props.prefix,
            this.props.data.savedSearch, this.props.data.rowTotalCount)

    }

    render() {
        return (
            <>
                <Button disabled={this.state.disabledButton} onClick={() => this.removeFilters()} className='hoverRed'>
                    <div className={"actionPanelButtonIcon " + "icon-deleteIcon"}/>
                    {sAction.translate("LBL_LIST_FILTER_DELETE")}
                </Button>
            </>
        )
    }
}