import detailDefault from '../../detailDefault';

export default class detailQuotes extends detailDefault {
    load(sAction, data) {
        if (sAction.dataGet(`${data.prefix}/fields/quote_stage/value`) === 'Active') {
            sAction.dataSet(`${data.prefix}/readonly`, false);
        }
        this.filterRelateOptions(sAction, data.prefix);
        const date_from = sAction.dataGet(data.prefix + '/fields/date_quote_from/value');
        const date_to = sAction.dataGet(data.prefix + '/fields/date_quote_to/value');

        if (!date_from || !date_to) { // kvuli clear a process
            sAction.dsClear();
            this.setDefaultDates(sAction, data, date_from, date_to);
            sAction.dsProcess();
        }

        this.changeLossReasonVisibility(sAction, data);
        this.setDefaultFilter(sAction, data);
        this.setDefaultFilterCustomer(sAction, data);
        this.setDefaultFilterCustomerBranch(sAction, data);
        this.setDefaultFilterCustomerContact(sAction, data);
        this.setDefaultFilterParentAccountsOnly(sAction, data);
    }

    update(sAction, data) {
        this.filterRelateOptions(sAction, data.prefix);
        switch (data.field) {
            case 'danova_sazba':
                sAction.dataSet(data.prefix + '/customData/productData/dph', data.value);
                break;
            case 'billing_account_id':
                this.setDefaultFilter(sAction, data);
                this.reloadProducts(sAction, data);
                sAction.checkAccountRelType(data.value.id, 'parent', 'billing_account_name', data.prefix, data.module);
                break;
            case 'currency_id':
                this.changeCurrencyValues(sAction, data);
                break;
            case 'kurz':

                const currency_id = sAction.dataGet(data.prefix + '/fields/currency_id/value');
                if (currency_id && currency_id !== '-99') {
                    // kdyz se meni kurz v eurech napr. z 25 Kc na 20 Kc - nabidka se prepocita dle defaultni meny
                    sAction.dataSet(data.prefix + '/fields/kurz/value', data.prevValue);

                    // this.changeCurrencyValues(sAction, {prefix: data.prefix, value: "-99"});
                    sAction.saveField({
                        way: data.prefix + '/fields/currency_id',
                        name: 'currency_id',
                        value: '-99',
                    }, true);

                    sAction.dataSet(data.prefix + '/fields/kurz/value', data.value);

                    // this.changeCurrencyValues(sAction, {prefix: data.prefix, value: currency_id});
                    sAction.saveField({
                        way: data.prefix + '/fields/currency_id',
                        name: 'currency_id',
                        value: currency_id,
                    }, true);
                }

                break;
            case 'cenotvorba_discount':
            case 'cenotvorba_discount_select':
                sAction.cenotvorbaChange('quote', {way: data.prefix, value: data.value, field: data.field});
                break;
            case 'accounts_quotes_1accounts_ida':
                sAction.checkAccountRelType(data.value.id, 'child', 'accounts_quotes_1_name', data.prefix, data.module);
                this.getAccountParent(sAction, data.prefix);
                break;
            case 'quote_stage':
                this.changeLossReasonVisibility(sAction, data);
                break;
            case 'accounts_quotes_2accounts_ida':
                this.setDefaultFilterCustomerBranch(sAction, data, true);
                sAction.accountChangeForMachines({way: data.prefix, id_value: data.value.id, name_value: data.value.name, field: 'account_hq_filter_id'});
                break;
            case 'accounts_quotes_3accounts_ida':
                sAction.accountChangeForMachines({way: data.prefix, id_value: data.value.id, name_value: data.value.name, field: 'account_branch_filter_id'});
                this.setDefaultFilterCustomerContact(sAction, data, true);
                break;
        }
        sAction.checkRelateErrors(data.prefix, data.module);
    }

    getAccountParent(sAction, prefix) {
        const id = sAction.dataGet(prefix + '/fields/accounts_quotes_1_name/def/id_value');
        const searchData = {
            module: 'Accounts',
            fields: ['id'],
            getFields: ['parent_id', 'parent_name'],
            value: id,
            limit: 1,
        };

        sAction.quickSearch(searchData, (response) => {
            if (response && response.length > 0) {
                sAction.dsClear();
                sAction.dsAdd('set', prefix + '/fields/billing_account_id/value', response[0].parent_id);
                sAction.dsAdd('set', prefix + '/fields/billing_account_name/def/id_value', response[0].parent_id);
                sAction.dsAdd('set', prefix + '/fields/billing_account_name/value', response[0].parent_name);

                sAction.dsAdd('set', prefix + '/changes/fields/billing_account_id', response[0].parent_id);
                sAction.dsAdd('set', prefix + '/changes/fields/billing_account_name', response[0].parent_id);
                sAction.dsAdd('set', prefix + '/changes/fields/billing_account_name', response[0].parent_name);
                sAction.dsProcess();
            }
        });
    }

    setDefaultFilter(sAction, data) {
        const billAccID = sAction.dataGet(data.prefix + '/fields/billing_account_id/value');
        const billAccNameIDVal = sAction.dataGet(data.prefix + '/fields/billing_account_name/def/id_value');
        const billAccIDChanges = sAction.dataGet(data.prefix + '/changes/fields/billing_account_id');
        let billAcc = billAccID;

        if (billAccNameIDVal) {
            billAcc = billAccNameIDVal;
        }
        if (billAccIDChanges) {
            billAcc = billAccIDChanges;
        }

        if (billAcc) {
            const defFilter = [
                {
                    field: 'parent_id',
                    footer: false,
                    type: 'eq',
                    value: billAcc,
                },
            ];
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/accounts_quotes_1_name/def/defaultFilter',
                defFilter,
            );
            sAction.dsProcess();
        }
    }

    /**
     * set default filter for customer account (accounts_quotes_2accounts_ida)
     * allow only selecting account with no parent (centraly)
     * @param {any} sAction
     * @param {any} data
     */
    setDefaultFilterCustomer(sAction, data) {
        const customerAccountDefaultFilter = sAction.dataGet(data.prefix + '/fields/accounts_quotes_2_name/def/defaultFilter');

        if (!customerAccountDefaultFilter) {
            // where parent_id is not set or is same as id
            const defFilterCustomer = [
                {
                    field: 'ticker_symbol',
                    footer: false,
                    type: 'neq',
                    value: '',
                },
            ];
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/accounts_quotes_2_name/def/defaultFilter',
                defFilterCustomer,
            );
            sAction.dsProcess();
        }
    }


    setDefaultFilterCustomerBranch(sAction, data, change = false) {
        let customerAccountId = data?.value?.id;
        let customerAccountName = data?.value?.name;

        if (!customerAccountId) {
            customerAccountId = (sAction.dataGet(data.prefix + '/fields/accounts_quotes_2accounts_ida/value') ??
                sAction.dataGet(data.prefix + '/fields/accounts_quotes_2accounts_ida/def/id_value'));
            if (!customerAccountId) {
                customerAccountId = sAction.dataGet(data.prefix + '/fields/accounts_quotes_2_name/def/id_value');
            }
            customerAccountName = sAction.dataGet(data.prefix + '/fields/accounts_quotes_2_name/value');
        }

        if (!customerAccountId) {
            return;
        }

        // if customer account is set, set default filter for customer account branch (accounts_quotes_3accounts_ida)
        if (customerAccountId) {
            const defFilterBranch = [
                {
                    field: 'parent_id',
                    footer: false,
                    type: 'eq',
                    value: customerAccountId,
                },
            ];
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/accounts_quotes_3_name/def/defaultFilter',
                defFilterBranch,
            );
            if (change) {
                // empty customer contact and customer branch
                sAction.dsAdd('set', data.prefix + '/fields/accounts_quotes_3accounts_ida/value', '');
                sAction.dsAdd('set', data.prefix + '/fields/accounts_quotes_3accounts_ida/def/id_value', '');
                sAction.dsAdd('set', data.prefix + '/fields/accounts_quotes_3_name/value', '');
                sAction.dsAdd('set', data.prefix + '/fields/accounts_quotes_3_name/def/id_value', '');
                sAction.dsAdd('set', data.prefix + '/changes/fields/accounts_quotes_3accounts_ida', '');
                sAction.dsAdd('set', data.prefix + '/changes/fields/accounts_quotes_3_name', '');

                sAction.dsAdd('set', data.prefix + '/fields/contacts_quotes_1contacts_ida/value', '');
                sAction.dsAdd('set', data.prefix + '/fields/contacts_quotes_1contacts_ida/def/id_value', '');
                sAction.dsAdd('set', data.prefix + '/fields/contacts_quotes_1_name/value', '');
                sAction.dsAdd('set', data.prefix + '/fields/contacts_quotes_1_name/def/id_value', '');
                sAction.dsAdd('set', data.prefix + '/changes/fields/contacts_quotes_1contacts_ida', '');
                sAction.dsAdd('set', data.prefix + '/changes/fields/contacts_quotes_1_name', '');
            }
            sAction.dsProcess();
        }
    }

    setDefaultFilterParentAccountsOnly(sAction, data) {
        const defFilter = [
            {
                field: 'rel_type',
                footer: false,
                type: 'eq',
                value: 'parent',
            },
        ];
        sAction.dsClear();
        sAction.dsAdd('set', data.prefix + '/fields/billing_account_name/def/defaultFilter',
            defFilter,
        );
        sAction.dsProcess();
    }

    setDefaultFilterCustomerContact(sAction, data, change = false) {
        let customerBranchAccountId = data?.value?.id;
        if (!customerBranchAccountId) {
            customerBranchAccountId = (sAction.dataGet(data.prefix + '/fields/accounts_quotes_3accounts_ida/value') ??
            sAction.dataGet(data.prefix + '/fields/accounts_quotes_3accounts_ida/def/id_value'));

            if (!customerBranchAccountId) {
                customerBranchAccountId = sAction.dataGet(data.prefix + '/fields/accounts_quotes_3_name/def/id_value');
            }
        }

        if (!customerBranchAccountId) {
            return;
        }

        // if customer account is set, set default filter for customer contact (contacts_quotes_1contacts_ida)
        if (customerBranchAccountId) {
            // contact with relate account_id = customerBranchAccountId
            const defFilterContact = [
                {
                    module: 'Accounts',
                    operandType: 'relate',
                    parentId: customerBranchAccountId,
                    relName: 'account_name',
                    relationship: ['accounts'],
                    type: 'eq',
                },
            ];

            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/contacts_quotes_1_name/def/defaultFilter',
                defFilterContact,
            );
            if (change) {
                // empty customer contact contacts_quotes_1contacts_ida
                sAction.dsAdd('set', data.prefix + '/fields/contacts_quotes_1contacts_ida/value', '');
                sAction.dsAdd('set', data.prefix + '/fields/contacts_quotes_1contacts_ida/def/id_value', '');
                sAction.dsAdd('set', data.prefix + '/fields/contacts_quotes_1_name/value', '');
                sAction.dsAdd('set', data.prefix + '/fields/contacts_quotes_1_name/def/id_value', '');
                sAction.dsAdd('set', data.prefix + '/changes/fields/contacts_quotes_1contacts_ida', '');
                sAction.dsAdd('set', data.prefix + '/changes/fields/contacts_quotes_1_name', '');
            }
            sAction.dsProcess();
        }
    }

    beforeSave(sAction, data) {
        let groups = sAction.dataGet(data.prefix + '/customData/productData/groups');

        if (groups) {
            groups = groups.toJS();
        }

        if (sAction.deviceType == 'mobile') {
            return true;
        }

        let allowSave = false;
        groups.forEachObject((value, key) => {
            value['lines'].forEachObject((line, lKey) => {
                if (line['name']) {
                    allowSave = true;
                }
            });
        });
        if (!allowSave) {
            sAction.error(sAction.translate('LBL_SAVE_PREVENTED_MISSING_PRODUCT'));
        }
        return allowSave;
    }

    setDefaultDates(sAction, data, date_from, date_to) {
        if (!date_from) {
            let today = new Date();
            today = this.formatDate(today);

            sAction.dsAdd('set', data.prefix + '/fields/date_quote_from/value', today);
            sAction.dsAdd('set', data.prefix + '/changes/fields/date_quote_from', today);
        }
        if (!date_to) {
            let newDateTo = new Date();
            newDateTo.setDate(newDateTo.getDate() + 14);
            newDateTo = this.formatDate(newDateTo);

            sAction.dsAdd('set', data.prefix + '/fields/date_quote_to/value', newDateTo);
            sAction.dsAdd('set', data.prefix + '/changes/fields/date_quote_to', newDateTo);
        }
    }

    formatDate(date) {
        const d = String(date.getDate()).padStart(2, '0');
        const m = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        const y = date.getFullYear();

        return y + '-' + m + '-' + d;
    }

    changeCurrencyValues(sAction, data) {
        // prepocitej produkty v nabidce podle zadaneho kurzu...

        if (!data.value && data.prevValue === '-99' || data.value === '-99' && !data.prevValue) {
            return;
        }

        if (!data.value) {
            data.value = '-99';
        }

        let kurz = 0;
        const kurz_field_value = sAction.dataGet(data.prefix + '/fields/kurz/value');
        if (kurz_field_value && parseFloat(kurz_field_value)) {
            kurz = kurz_field_value;
        }

        sAction.dataSet(data.prefix + '/customData/productData/currency_id', data.value);

        const groups = sAction.dataGet(data.prefix + '/customData/productData/groups');
        sAction.dsClear();
        let group_i = 0;
        for (const group of groups) {
            let line_i = 0;
            for (const line of group.lines) {
                const list_price = sAction.currencyConvert(line.list_price, data.prevValue, data.value, kurz);
                const cost_price = sAction.currencyConvert(line.cost_price, data.prevValue, data.value, kurz);
                const discount_amount = sAction.currencyConvert(line.discount_amount, data.prevValue, data.value, kurz);

                sAction.dsAdd('set', data.prefix + '/customData/productData/groups/' + group_i + '/lines/' + line_i + '/list_price', list_price);
                sAction.dsAdd('set', data.prefix + '/customData/productData/groups/' + group_i + '/lines/' + line_i + '/cost_price', cost_price);
                if (line.discount_select == 0) {
                    sAction.dsAdd('set', data.prefix + '/customData/productData/groups/' + group_i + '/lines/' + line_i + '/discount_amount', discount_amount);
                }
                line_i++;
            }
            group_i++;
        }
        sAction.dsProcess();
    }

    filterRelateOptions(sAction, prefix) {
        let parentId = sAction.dataGet(prefix + '/fields/billing_account_name/def/id_value'); // centrala
        if (sAction.dataGet(prefix + '/fields/accounts_quotes_1_name/def/id_value')) {
            parentId = sAction.dataGet(prefix + '/fields/accounts_quotes_1_name/def/id_value'); // pobocka
        }

        const filter = {
            parent: {
                operandType: 'relate',
                parentId: parentId,
                module: 'Accounts',
                relationship: ['accounts'],
                name: 'account_name',
                relName: 'contacts',
                type: 'eq',
            },
        };
        sAction.dataSet(prefix+'/fields/billing_contact_name/def/defaultFilter', filter);
    }

    reloadProducts(sAction, data) {
        if (!sAction.dataGet(data.prefix + '/id') && data.value.id) {
            const products = sAction.dataGet(data.prefix + '/customData/productData/groups');

            sAction.load();
            sAction.rest.post('reloadProductPrices', {groups: products, accID: data.value.id}, (returnData) => {
                sAction.unLoad();
                if (returnData?.status) {
                    sAction.dsClear();
                    returnData.message?.data?.groups?.forEach((group, groupKey) => {
                        group?.lines?.forEach((product, prodKey) => {
                            sAction.dsAdd('set', data.prefix + '/customData/productData/groups/' + groupKey + '/lines/' + prodKey + '/discount_amount', product.discount_amount);
                            sAction.dsAdd('set', data.prefix + '/customData/productData/groups/' + groupKey + '/lines/' + prodKey + '/discount_select', product.discount_select);
                        });
                    });
                    sAction.dsProcess();
                }
            });
        }
    }

    changeLossReasonVisibility(sAction, data) {
        const lossStates = ['Closed Lost', 'Canceled', 'Closed canceled client'];

        if (lossStates.includes(sAction.dataGet(`${data.prefix}/fields/quote_stage/value`))) {
            sAction.dataSet(`${data.prefix}/fields/loss_reason/def/visible`, true);
            return;
        }

        sAction.dataSet(`${data.prefix}/fields/loss_reason/def/visible`, false);
    }
}

