export default function sendServiceOrderInvitation(params) {
    this.load();

    const list = this.dataGet(params.prefix);
    const records = list.selected;
    let filterData = null;
    if(records === 'all'){
        const savedSearch = list.actSavedSearch;
        filterData = this.reloadListParams({
            list,
            prefix: params.prefix,
            savedSearch,
            columns: false,
        });
        filterData["selected"] = list.selected;
        filterData["module"] = list.modul;
        filterData["type"] = list.type;
    }

    const requestData = {
        serviceOrderIds: records,
        filter: filterData,
    }

    this.rest.post('sendServiceOrderInvitation', requestData, (ret) => {
        this.unLoad();
        if (!ret || ret?.status === false) {
            this.error(this.translate('LBL_SERVICE_ORDER_INVITATION_SEND_FAILED'));
            return;
        }

        const sendWithoutPDF = ret?.message?.data?.sendWithoutPDF ?? ret?.errorMessage?.data?.sendWithoutPDF ?? {};
        const notSendOrders = ret?.message?.data?.notSendOrders ?? ret?.errorMessage?.data?.notSendOrders ?? {};

        if(ret?.status === true && sendWithoutPDF.count === 0 && notSendOrders.count === 0 && ret?.message?.data?.sendFromSystem === false){
            this.alert(this.translate('LBL_SERVICE_ORDER_INVITATION_SENT'));
            return;
        }
        if(sendWithoutPDF.count){
            let html = `${this.translate('LBL_SEND_WITHOUT_PDF')}:<br>`;
            for (const item of sendWithoutPDF.ids ?? []){
                html += `<a href="${item?.link}" target='_blank'>${item.name}</a><br>`;
            }
            this.alert(html, this.translate('LBL_SEND_WITHOUT_PDF'))
        }
        if(notSendOrders.count){
            let html = `${this.translate('LBL_NOT_SEND')}:<br>`;
            for (const item of notSendOrders.ids ?? []){
                html += `<a href="${item?.link}" target='_blank'>${item.name}</a><br>`;
            }
            this.error(html, this.translate('LBL_NOT_SEND'));
        }
        if(ret?.message?.data?.sendFromSystem === true){
            this.error(this.translate('LBL_SEND_FROM_SYSTEM_EMAIL'));
        }
    });
}
