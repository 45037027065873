export default function accessRequestDenyPopup(paramData) {
    console.log(paramData);
    this.popup('accessRequestDeny', {
        params: {
            id: paramData.id,
            module: paramData.module,
            status: paramData.stage,
        },
        header: this.translate('LBL_ACCESS_REQUEST_DENY', paramData.module),
    });
}
