export default function checkNewNotifications() {
    if (window.Cypress || this.getStorage('Cypress')) {
        return;
    }
    if (localStorage.getItem('notifications') == 'false') {
        console.log('[ info ] No notifications - dev mode');
        return;
    }
    //   return;
    this.notificationIntervalDuration = 15000;
    this.notificationIntervalFunction = function() {
        this.rest.get('getNotifyCount', (data) => {
            const status = data.status;
            const number = parseInt(data.number);

            if (status === true && isNaN(number) === false) {
                const count = this.dataGet('notifications/notice');
                this.dsClear();
                this.dsAdd('set', 'conf/loggedOut', false);
                this.dsAdd('set', 'conf/offline/status', false);
                this.dsAdd('set', 'conf/offline/counter', 0);
                this.dsProcess();
                restoreInterval();

                if (count !== number) {
                    this.dataSet('notifications/notice', number);

                    const toastLangMap = {
                        1: this.translate('LBL_NOTIFY_TOAST'),
                        2: this.translate('LBL_NOTIFY_TOAST'),
                        3: this.translate('LBL_NOTIFY_TOAST'),
                        4: this.translate('LBL_NOTIFY_TOAST'),
                        5: this.translate('LBL_NOTIFY_TOAST_MORE'),
                    };
                    let desc = toastLangMap[number];

                    if (desc === undefined) {
                        desc = toastLangMap[5];
                    }
                    desc = desc.replace('{number}', number);
                    this.toast({
                        name: 'Nová upozornění',
                        description: desc,
                        sAction: 'openNotBase',
                    });
                }
            }
        }, false);

        const restoreInterval = () => {
            if (this.notificationIntervalDuration !== 15000) {
                this.notificationIntervalDuration = 15000;
                clearInterval(this.notificationInterval);
                this.notificationInterval = setInterval(() => {
                    this.notificationIntervalFunction();
                }, this.notificationIntervalDuration);
            }
        };
    };

    this.notificationInterval = setInterval(() => {
        this.notificationIntervalFunction();
    }, this.notificationIntervalDuration); // 15s
}
