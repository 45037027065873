export default function saveMerge(way, data, returnUrl) {
    const oldRecords = [];
    data.records.forEach((record) => {
        oldRecords.push(record.get('id'));
    });

    const newRecord = data.newRecord.toJS();
    delete newRecord.id;

    const paramData = {
        record: newRecord,
        oldRecords,
        module: data.module,
    };
    this.load();
    this.rest.post('mergeSave', paramData, (returnData) => {
        if (returnData.record != undefined) {
            if (returnUrl) {
                window.location.hash = returnUrl;
            } else {
                window.location.hash = '#detail/'+data.module+'/'+returnData.record;
            }
        }
    });
}
