/**
 * Display an error popup
 * @param {string} message
 * @param {string} title
 * @param {bool} exception
 * @param {string|null} response
 */
export default function error(message, title = this.translate('LBL_ERROR'), exception = false, response = null) {
    const data = {
        color: 'red',
        header: title,
        buttons: [{label: this.translate('LBL_CLOSE_POPUP'), callback: () => this.popupHide()}],
    };
    if (exception && response) {
        message = `${message}<br/><br/><pre>${response}</pre>`;
    }
    this.popup(message, data);
}
