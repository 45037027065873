export default function resetPassPortalUser(params) {
    this.confrim(this.translate('LBL_CONFIRM_USER_RESET_PASS', 'acm_portal_users'), () => {
        this.popupHide();
        this.load();
        this.rest.post('resetPassPortalUser', params, (returnData) => {
            this.unLoad();
            if (returnData.status) {
                const data = {
                    header: 'Info',
                    buttons: [{label: 'OK', callback: () => {
                          this.popupHide();
                          location.reload();
                      }}],
                };

                this.popup(this.translate(returnData.message.text), data);
            } else {
                this.error(this.translate(returnData.errorMessage.text));
            }
        });
    });
}
