export default function getProductTemplate(id, type, way, index = null) {
    this.load();
    const accID = this.dataGet(way.split("/customData/")[0] + '/fields/billing_account_name/def/id_value');
    const getFields = ['id', 'mj', 'name', 'cost_price', 'list_price', 'discount_price', 'currency_id', 'description',
                       'product_type', 'percents', 'inherit', 'picture', 'category_id', 'discount_select', 'rozmer', 'stock_hlavni',
                       'stock_praha', 'stock_lovosice', 'stock_pardubice', 'stock', 'stock_hlavni_rezervace',
                        'stock_praha_rezervace', 'stock_lovosice_rezervace', 'stock_pardubice_rezervace', 'actionprice'];
    const data = {
        id: id,
        getFields: getFields,
        accID: accID,
    };
    const fullWay = index !== null ? way + '/' + index : way;
    this.rest.post('getProductTemplate', data, returnData => {
        if (returnData['status']) {
            var line = returnData['product'];
            let current_stock = {}
            let stock_rezervace = {}
            // Chtelo by to dodelat prepocet men z produktu
            // let kurz = this.dataGet(way.split('customData')[0] + 'fields/price_cur/value');
            //const currency_id = sAction.dataGet(way.split("/productData/", 1)[0]+"/currency_id");//TTT cesta natvrdo
            // const prefix = way.split("/")[0];
            const prefix = way.split("/customData/")[0];

            const currency_id = this.dataGet(prefix+"/fields/currency_id/value");
            // let convRate = sAction.dataGet(prefix+"/fields/kurz/value");
            line.forEachObject((value, name) => {
                if(!name){
                    return;
                }

                if(name.includes("stock")){
                    if(name.includes("rezervace")){
                        stock_rezervace[name] = value
                        return;
                    }

                    current_stock[name] = value
                }
            })

            line.cost_price = this.currencyConvert(line.cost_price, line.currency_id, currency_id/*, convRate*/);

            line.list_price = this.currencyConvert(line.list_price, line.currency_id, currency_id/*, convRate*/);
            if(line.actionprice && parseFloat(line.actionprice)){
                line.list_price = this.currencyConvert(line.actionprice, line.currency_id, currency_id)
            }
            line.discount_amount = this.currencyConvert(line.discount_amount, line.currency_id, currency_id/*, convRate*/);
            line.current_stock = current_stock
            line.stock_rezervace = stock_rezervace

            if (type != 'prodName') {
                line.product_template_id = line.id;
                line.id = '';
            }
            this.updateDetailCustom(type, { way: fullWay, item: line, template: true });
            if (returnData['bound']) {
                if (returnData['bound']['required']) {
                    returnData['bound']['required'].forEach((item) => {
                        item.cost_price = this.currencyConvert(item.cost_price, item.currency_id, currency_id);
                        item.list_price = this.currencyConvert(item.list_price, item.currency_id, currency_id);
                        item.product_template_id = item.id;
                        item.id = '';
                        this.updateDetailCustom("addProdLine", {way: way, item: item});
                    });
                }
                if (returnData['bound']['other']) {
                    let groupWay = way.split('groups')[0] + 'groups';
                    let groupID = this.dataGet(groupWay, null, true).length;
                    this.updateDetailCustom("addProdGroup", {way: groupWay, name: 'Další specifikace'});
                    returnData['bound']['other'].forEach((item) => {
                        item.cost_price = this.currencyConvert(item.cost_price, item.currency_id, currency_id);
                        item.list_price = this.currencyConvert(item.list_price, item.currency_id, currency_id);
                        item.product_template_id = item.id;
                        item.id = '';
                        this.updateDetailCustom("addProdLine", {way: groupWay + '/' + groupID + '/lines', item: item})
                    });
                }
                if (returnData['bound']['possible']) {
                    let groupWay = way.split('groups')[0] + 'groups';
                    let groupID = this.dataGet(groupWay, null, true).length;
                    this.updateDetailCustom("addProdGroup", {way: groupWay, name: 'Možné příplatky'});
                    returnData['bound']['possible'].forEach((item) => {
                        item.cost_price = this.currencyConvert(item.cost_price, item.currency_id, currency_id);
                        item.list_price = this.currencyConvert(item.list_price, item.currency_id, currency_id);
                        item.product_template_id = item.id;
                        item.id = '';
                        this.updateDetailCustom("addProdLine", {way: groupWay + '/' + groupID + '/lines', item: item});
                    });
                }
            }
        }
        this.unLoad();
    });
}
