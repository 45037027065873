import React from "react";
import PureComponent from "../../pure";

import InputText from "../../formElements/InputText";
import Button from "../../formElements/Button";
import MultiSelect from "../../formElements/MultiSelect";
import Checkbox from "../../formElements/CheckBox";
import  sAction  from "sAction";
import RolesTable from "../roles/RolesTable";
import TabContainer from "../../formElements/TabContainer";

export default class ReportWindowSave extends PureComponent {
  constructor(props) {
    super(props);

    var selectedUsers = [];
    var selectedRoles = []

    if (this.props.data.metadata.selectedRoles){
      selectedRoles = this.props.data.metadata.selectedRoles

    }

    if (this.props.data.metadata.selectedUsers){
      selectedUsers = this.props.data.metadata.selectedUsers

    }

    let activeTab = 0
    if(this.props.data.changes.activeTab === 1){
      activeTab = 1
    }
    this.state = {
      selectedUsers: selectedUsers,
      selectedRoles: selectedRoles,
      activeTab: activeTab,
      inputFieldError: false,
      multienumError: false,
    };

    this.name = React.createRef();
    this.description = React.createRef();
    this.id = React.createRef();
  }

  //preda se nazev v stateu a honota ktera je v poli, pokud je to pole nebo list musi se checkovat pres delku
  checkForError(errorField, value){
    if(value && value.length > 0){
      this.setState({[errorField]: false})
      return
    }
    this.setState({[errorField]: true})
  }

  updateSavedValues(way, value){
    this.props.data.changes[way] = value
  }

  changeActiveTab(newTab) {
    if (newTab == this.state.activeTab) {
      return;
    }
    this.updateSavedValues("activeTab", newTab)
    this.setState({ activeTab: newTab });
  }
  updateData = (name, value) => {
    this.setState({ [name]: value });
  };
  save(asNew = false) {
    const data = this.props.data
    const propsData = this.props.data;
    const nameInput = this.name.current;
    const idInput = this.id.current;
    const name = nameInput.value;
    const id = asNew ? null : idInput.value;
    const cat = 'report'; //Natvrdo se nastavi 'report', vzhledem k tomu ze katerogie je zbytecna
    const show = data.metadata.show;
    const description = this.description.current.value;
    var admin_only = 0;
    var selectedUsers = [];
    var selectedRoles = [];
    if (sAction.userPreferences.is_admin == "1") {
        admin_only = propsData.metadata.admin_only ? 1 : 0;
        selectedUsers = admin_only == 1 ? [] : this.state.selectedUsers;
        selectedRoles = admin_only == 1 ? [] : this.state.selectedRoles;
    }
    const params = {
      name,
      cat,
      show,
      id,
      description,
      admin_only,
      selectedUsers: selectedUsers,
      selectedRoles: selectedRoles,
    };
    if(!this.canSave(params)){
      sAction.error(sAction.translate("LBL_FILL_REQUIRED_FIELDS"))
      return
    }
    sAction.reportWindowSaveSearch(params);
  }
  delete() {
    sAction.reportWindowDeleteSearch(this.id.current.value);
  }
  changeAdminOnly(event) {
    sAction.dataSet("reportWindow/metadata/admin_only", event ? 1 : 0);
  }

  canSave(params){
    if(!params.name){
      this.setState({inputFieldError: true})
    }

    if(!params.show){
      this.setState({multienumError: true})
    }

    return params.name && params.show && params.show.size > 0
  }

  render() {
    const { selectedUsers, selectedRoles, activeTab, inputFieldError, multienumError } = this.state;
    const data = this.props.data;
    var name = null;
    var id = null;
    var description = null;
    var admin_only = 0;
    var showValue = [];
    if (data.metadata != null) {
      name = data.metadata.name;
      id = data.metadata.id;
      showValue = data.metadata.show ?? []
      description = data.metadata.description;
      if (data.metadata.admin_only != undefined) {
        admin_only = data.metadata.admin_only ? 1 : 0;
      }
    }
    if (typeof showValue === "object" && showValue != null) {
      try {
        showValue = showValue.toJS();
      } catch (e) { }
    }

    const showInOptions = sAction.getEnumForSelect("savedSearchShowIn");

    let saveChangesButton = null;
    var deleteButton = null;
    if (data.metadata.id && (data.metadata.canEdit === true || sAction.userPreferences.is_admin == "1")){
      saveChangesButton = (
        <Button onClick={() => this.save(false)}>
          <div
            className={"actionPanelButtonIcon " + "icon-saveIcon"}
          />
          {sAction.translate("LBL_REPORT_SAVE_EDIT")}
        </Button>
      );
      deleteButton = (
        <Button onClick={() => this.delete()} className='hoverRed'>
          <div
            className={"actionPanelButtonIcon " + "icon-deleteIcon"}
          />
          {sAction.translate("LBL_REPORT_DELETE")}
        </Button>
      );
    }
    let inputFieldClassName = "withBorder "
    const roles = sAction.translate("LBL_ROLES", "Home");
    const users = sAction.translate("LBL_USERS", "Home");
    const tabs = [roles, users]; //"Role", "Uživatelé"
    var userSelection = null;
    let renderMenu = (
      <TabContainer
        onChange={(e, value) => this.changeActiveTab(value)}
        value={activeTab}
        tabs={tabs}
      />
    );
    if (admin_only !== 1) {
      userSelection = (
        <tr>
          <td className="formlabel">{sAction.translate("LBL_REPORT_USER_SELECTION")}</td>
          <td>
            <div className="acmTabContainer">{renderMenu}</div>
            <div className={activeTab != 0 ? "homeTableHide hidden" : "homeTableHide"}>
              <RolesTable
                module="ACLRoles"
                selectedData={selectedRoles.length > 0 ? selectedRoles : data.changes.selectedRoles}
                onChange={data => {this.updateData("selectedRoles", data); this.updateSavedValues("selectedRoles", data)}}
              />
            </div>
            <div className={activeTab != 1 ? "homeTableHide hidden" : "homeTableHide"}>
              <RolesTable
                keepSelf={true}
                module="Users"
                selectedData={selectedUsers.length > 0 ? selectedUsers : data.changes.selectedUsers}
                onChange={data => {this.updateData("selectedUsers", data); this.updateSavedValues("selectedUsers", data)}}
              />
            </div>
          </td>
        </tr>
      );
    }

    return (
      <div className="reportWindowSaveContainer flexCenter">
        <div className="simpleForm">
          <table>
            <tbody>
              <tr>
                <td className="formlabel">
                  <div>{sAction.translate("LBL_REPORT_NAME")}
                    <span className="required">*</span>
                  </div>
                </td>
                <td>
                  <input ref={this.id} type="hidden" defaultValue={id} />
                  <InputText
                    className={inputFieldError ? inputFieldClassName + "reportWindowFieldError" : inputFieldClassName}
                    defaultValue={name ? name : data.changes.changedName}
                    myRef={this.name}
                    onChange={e => {this.updateSavedValues("changedName", e.target.value);
                                    this.checkForError("inputFieldError", e.target.value)}}
                    error={inputFieldError}
                  />

                </td>
              </tr>
              <tr>
                <td className="formlabel">
                    <div>{sAction.translate("LBL_REPORT_ORDER_IN")}
                        <span className="required">*</span>
                    </div>
                </td>
                <td>
                  <MultiSelect
                    className={multienumError ? "reportWindowFieldError " : "withBorder " }
                    id="reportWindowMultiSelect"
                    options={showInOptions}
                    defaultValue={showValue ? showValue : data.changes.showValues}
                    onBlur={values => {sAction.dataSet("reportWindow/metadata/show",values); this.checkForError("multienumError", values)}}
                  />
                </td>
              </tr>
              <tr>
                <td className="formlabel">{sAction.translate("LBL_REPORT_DESCRIPTION")}</td>
                <td>
                  <textarea className="reportWindowTextarea"
                            defaultValue={description ? description : data.changes.description}
                            onChange={e => this.updateSavedValues("description", e.target.value)}
                            ref={this.description} />
                </td>
              </tr>
              {sAction.userPreferences.is_admin == "1" && (
                <tr>
                  <td className="formlabel">{sAction.translate("LBL_REPORT_SYSTEM_VIEW")}</td>
                  <td>
                    <Checkbox
                      defaultValue={admin_only == true ? 1 : 0}
                      onChange={e => this.changeAdminOnly(e)}
                    />
                  </td>
                </tr>
              )}
              {sAction.userPreferences.is_admin == "1" && userSelection}
              <tr>
                <td colSpan="2">
                  <div className="viewActionPanelButtons flexRight">
                    {deleteButton}
                    {saveChangesButton}
                    <Button onClick={() => this.save(true)}>
                      <div
                        className={"actionPanelButtonIcon " + "icon-saveIcon"}
                      />
                      {sAction.translate("LBL_REPORT_SAVE_AS")}
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
