import detailDefault from '../../detailDefault';

export default class detailacm_machines_attriburtes extends detailDefault {
    load(sAction, data) {
        this.switchAttrFieldType(
            sAction,
            sAction.dataGet(data.prefix + "/fields/type/value"),
            data.prefix
        );
    }

    update(sAction, data) {
        if (data.field === 'type') {
            this.switchAttrFieldType(sAction, data.value, data.prefix);
        }
    }

    switchAttrFieldType(sAction, type, prefix) {
        const way = prefix + '/fields/value';

        let fieldType = '';
        let field = sAction.dataGet(way);

        switch(type) {
            case 'varchar':
                // fieldType = 'varchar';
                break;
            case 'enum':
                // fieldType = 'AcmDynamicEnum';
                break;
        }

        // field = field.setIn(['def', 'type'], fieldType);

        // sAction.dataSet(way, field);
    }
}
