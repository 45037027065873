export default function accessRequestDeny(data) {
    this.load();
    console.log(data);
    
    this.rest.post(
        'accessRequestDeny',
        {
            record: data.id,
            reason: data.reason,
            status: data.status,
            sendReason: data.sendReason
        },
        (returnData) => {
            this.unLoad();
            this.popupHide();
            
            if (returnData.status) {
                this.route();
            } else {
                this.error(this.translate(returnData.errorMessage?.text));
            }
        }
    );
}
