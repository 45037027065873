export default function abraInvoicePrint(params) {
    this.downloadFile({
        id: params.id,
        name: params.name,
        module: 'acm_invoices',
        params: {
            customAction: 'abraInvoicePrint',
        },
    });
}
