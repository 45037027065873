export default function adminXHR(name, data, callback) {
  console.log(name);
  var xhr = new XMLHttpRequest();
  let xdebug = !this.param.xdebugEnabled ? "" : "?XDEBUG_SESSION_START=1";
  xhr.open("POST", this.param.adminURL + name + xdebug);
  // xhr.setRequestHeader("Content-Type", "application/json");
  //xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  // xhr.withCredentials = true;
  xhr.setRequestHeader("sID", this.rest.getCookie("sID"));
  xhr.onreadystatechange = data => {
    if (xhr.readyState === xhr.DONE) {
      if (xhr.status === 200) {
        callback(xhr.response);
      }
    }
  };
  if (data != null) {
    let param = this.objectToFormdata(data);
    xhr.send(param);
  } else {
    xhr.send();
  }
}