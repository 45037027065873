export default function createEshopUserFromContact(recordData) {
    if (!recordData.id) {
        return;
    }
    
    this.load();
    this.rest.post(
        "createEshopUserFromContact",
        {
            contactId: recordData.id,
        },
        (returnData) => {
            this.unLoad();
            
            if (!returnData.status) {
                this.alert(this.translate(returnData.error));
            } else {
                window.open('#detail/acm_portal_users/' + returnData.data.id, '_blank');
            }
        }
    );
}
