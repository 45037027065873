
import detailDefault from '../../detailDefault';

export default class detailacm_activity_log extends detailDefault {
    load(sAction, data) {
        const logType = sAction.dataGet(data.prefix + '/fields/logtype/value');

        // hide all custom fields
        sAction.dsClear();
        sAction.dsAdd('set', data.prefix + '/fields/search_text/def/visible', false);
        sAction.dsAdd('set', data.prefix + '/fields/product_template_id/def/visible', false);
        sAction.dsAdd('set', data.prefix + '/fields/product_template_name/def/visible', false);
        sAction.dsAdd('set', data.prefix + '/fields/product_qty/def/visible', false);

        switch (logType) {
            case 'search':
                // show search fields
                sAction.dsAdd('set', data.prefix + '/fields/search_text/def/visible', true);
                break;
            case 'cartEntry':
                // show cart entry fields
                sAction.dsAdd('set', data.prefix + '/fields/product_template_id/def/visible', true);
                sAction.dsAdd('set', data.prefix + '/fields/product_template_name/def/visible', true);
                sAction.dsAdd('set', data.prefix + '/fields/product_qty/def/visible', true);
                break;
        }

        sAction.dsProcess();
    }

    update(sAction, data) {
    }
}
