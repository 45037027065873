import React from "react";
import PureComponent from "../../pure";

import List from "../../list/list";
import  sAction  from "sAction";

class RelateListView extends PureComponent {
  render() {
    var data = this.props.data;
    var popupData = this.props.popupData;

    var callback = popupData.get("callback");
    var categories = null;

    var listView = null;
    var treeComp = null;

    const module = data.get("modul");

    if (module == "ProductTemplates") {
      categories = popupData.get("categories");
    }

    if (data != undefined && module != undefined) {
      const id = sAction.getNewPopupId("relateListView");
      listView = (
        <List
          way={"conf/popup" + id + "/data/listView"}
          key="popupList"
          data={data}
          categories={categories}
          selectCallBack={callback}
          module={module}
        />
      );
    }
    return (
      <React.Fragment>
        {treeComp}
        {listView}
      </React.Fragment>
    );
  }
}

export default RelateListView;
