export default { 
    fields: [
        {
            name: "quantity",
            type: "number",
            lbl: "LBL_LIST_QUANTITY",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 6
        },
        {
            name: "name",
            type: "relateProd",
            lbl: "LBL_LIST_PRODUCT_NAME",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 30
        },
        {
            name: "cost_price",
            type: "price",
            lbl: "LBL_LIST_COST_PRICE",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 12
        },
        {
            name: "list_price",
            type: "price",
            lbl: "LBL_LIST_LIST_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 12
        },
        {
            name: "discount_amount",
            type: "price",
            lbl: "LBL_LIST_DEAL_TOT",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 12
        },
        {
            name: "discount_select",
            type: "discount_select",
            lbl: "",
            lblMod : "Products",
            visible: true,
            disabled: false,
            width: 6
        },
        {
            name: "margin_check",
            type: "semaphore",
            lbl: "LBL_MARGIN_CHECK",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 4
        },
        {
            name: "discount_price",
            type: "price",
            lbl: "LBL_LIST_DISCOUNT_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 12
        },
        {
            name: "total_price",
            type: "price",
            lbl: "LBL_LIST_TOTAL_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 12
        },
        {
            name: "opce_c",
            type: "checkbox",
            lbl: "LBL_LIST_OPCE",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 5
        },
        {
            name: "stock",
            type: "float",
            lbl: "LBL_STOCK_CELKEM",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 0
        },
        {
            name: "stock_hlavni",
            type: "float",
            lbl: "LBL_STOCK_HLAVNI",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 0
        },
        {
            name: "stock_lovosice",
            type: "float",
            lbl: "LBL_STOCK_LOVOSICE",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 0
        },
        {
            name: "stock_pardubice",
            type: "float",
            lbl: "LBL_STOCK_PARDUBICE",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 0
        },
        {
            name: "stock_praha",
            type: "float",
            lbl: "LBL_STOCK_PRAHA",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 0
        },
        {
            name: "stock_hlavni_rezervace",
            type: "float",
            lbl: "LBL_STOCK_REZERVACE_HLAVNI",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 0
        },
        {
            name: "stock_lovosice_rezervace",
            type: "float",
            lbl: "LBL_STOCK_REZERVACE_LOVOSICE",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 0
        },
        {
            name: "stock_pardubice_rezervace",
            type: "float",
            lbl: "LBL_STOCK_REZERVACE_PARDUBICE",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 0
        },
        {
            name: "stock_praha_rezervace",
            type: "float",
            lbl: "LBL_STOCK_REZERVACE_PRAHA",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 0
        },
        {
            name: "stock_render_field",
            type: "stock",
            lbl: "LBL_STOCK_RENDER",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 5
        },
        {
            name: "actionprice",
            type: "float",
            lbl: "LBL_ACTIONPRICE",
            lblMod: "Quotes",
            visible: false,
            disabled: false,
            width: 0
        }

    ],
    sums : [
        {
            type: "addBtn",
            visible: true,
            width: "36%",
        },
        {
            type: "space",
            visible: true,
            width: "20px",
        },
        // {
        //     name: "total_cost_price",
        //     type: "price",
        //     lbl: "LBL_TOTAL_COST_PRICE",
        //     lblMod: "Quotes",
        //     visible: true,
        //     width: "9%"
        // },
        {
            name: "total_list_price",
            type: "price",
            lbl: "LBL_SUBTOTAL",
            lblMod: "Quotes",
            visible: true,
            width: "12%"
        },
        {
            name: "total_discount_amount",
            type: "price",
            lbl: "LBL_LIST_DEAL_TOT",
            lblMod: "Quotes",
            visible: true,
            width: "12%"
        },
        {
            type: "space",
            visible: true,
            width: "21%",
        },
        {
            name: "total_discount_price",
            type: "price",
            lbl: "LBL_NEW_SUB",
            lblMod: "Quotes",
            visible: true,
            width: "12%"
        }
    ]
};
