import sAction from "sAction"

export default function formatNumberToHours(decimalTime, format) {

  if (decimalTime == null) {
    return "-"
  }

  decimalTime = parseFloat(decimalTime.replace(',', '.').replace(' ', '')) ?? 0

  let time = {}
  time.h = Math.floor(decimalTime)
  time.m = calcMinutes(decimalTime, format)
  time.s = Math.round(decimalTime * 3600 % 60) // upravit

  time = testOverflow(time,format)

  return format.split('').reduce((output, letter) => {
    return `${output}${(time[letter])?.toString().padStart(2, '0') ?? letter}`
  }, '')
}

/** TODO Najít nějaké lepší řešení, toto je quickfix, aby toto issue nezdrzovalo preprod
 * JE potreba, aby se vsechny hodnoty floor() az na tu poslední, která se bude round()
 * toto reseni umoznuje pouzivat pouze formaty h:m:s nebo h:m
 *
 * @param decimalTime
 * @param format
 * @returns {number}
 */

function calcMinutes(decimalTime, format) {
  let minutes = decimalTime * 60 % 60
  return haveSeconds(format)?Math.floor(minutes):Math.round(minutes);
}

function testOverflow(time,format) {

  if (parseInt(time.s) >= '60' && haveSeconds(format)){
    time.m = (parseInt(time.m)+1)
    time.s = (parseInt(time.s)-60)
  }
  if (parseInt(time.m) >= '60'){
    time.h = parseInt(time.h)+1
    time.m = (parseInt(time.m)-60)
  }

  return time
}

function haveSeconds(format){
  return format.indexOf('s') !== -1;
}

