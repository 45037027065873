import React from "react";

import FormField from "./formField.js";
import  sAction  from "sAction";
import InputText from "../formElements/InputText";

export default class DefaultInputField extends FormField {
  constructor() {
    super();
    this.state = {
      state: "fine",
    };
    this.input = React.createRef();
  }
  componentDidMount() {
    super.componentDidMount();
    const newRecord = this.props.newRecord;
    if (!newRecord) {
      //    this.refs.input.focus();
      //  this.refs.input.select();
    }
    this.defaultValue = this.getValue();
  }
  getValue() {
    return this.input.current.value;
  }
  save(param = {}) {
    const value = this.getValue();
    if (this.check()) {
      const data = {
          way: this.props.way,
          name: this.props.data.name,
          value,
          ...param
      }
      this.saveField(data);
    }
  }
  saveField(data) {
    sAction.saveField(data);
  }
  check() {
    var required = this.props.data.def.get("required");
    if (required === undefined) {
      required = false;
    }
    const reg = this.state.reg;
    const value = this.getValue();
    var ok = true;

    if (required === true && (value === "" || value === null)) {
      ok = false;
      this.setState({ ok: ok });
    } else if (required === true && value != "") {
      if (reg != undefined && !reg.test(value)) {
        ok = false;
        this.setState({ ok: ok });
      }
    } else if (required === false && value != "") {
      if (reg != undefined && !reg.test(value)) {
        ok = false;
        this.setState({ ok: ok });
      }
    }
    if (ok === false) {
      this.lockRecord();
    }
    return ok;
  }
  lockRecord() {
    sAction.dataSet(this.props.prefix + "/canSave", false);
  }
  cancel() {
    sAction.dataSet(this.props.way + "/actEdit", false);
  }
  onBlur(e) {
    const relTarget = e.relatedTarget;
    if (relTarget == null) {
      this.save();
    } else {
      var parent = relTarget.closest(
        "div[data-fieldname='" + this.props.data.name + "']"
      );
      if (parent == null) {
        this.save();
      }
    }
  }
  keyUpCall(e) {
    const keyCode = e.keyCode;
    if (keyCode == 13 && e.ctrlKey === false) {
      this.save();
    } else if (keyCode == 27) {
      this.cancel();
    }
  }

  getCurrentValue() {
    return this.props.data.value;
  }

  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    const ok = this.state.ok;
    // var inputClass = "form-control form-control-sm";
    var containerClass = "inputEditContainer";
    var error = false;
    if (ok === false) {
      //  inputClass += " wrongInput";
      error = true;
    }

    var value = this.getCurrentValue();
    if (newRecord && (value == "" || value == undefined)) {
      value = "";
    }
    else{
      value = sAction.decodeHTMLEntities(value);
    }


    return (
      <div className={containerClass} data-fieldname={data.name}>
        <div className="inputContainer">
          <InputText
            autoFocus={true}
            onKeyUp={e => this.keyUpCall(e)}
            onKeyDown={e => this.onKeyDown(e)}
            myRef={this.input}
            onBlur={e => this.onBlur(e)}
            error={error}
            defaultValue={value}
            id={data.name}
            name={data.name}
          />
        </div>
        {!newRecord && (
          <div className="buttonContainer">
            <div
              tabIndex="2"
              onClick={() => this.save()}
              className="inputEditButton"
            >
              <div className={"icon-detailConfrim"} /> 
            </div>
            <div
              tabIndex="3"
              onClick={() => this.cancel()}
              className=" inputEditButton"
            >
              <div className={"icon-detailCancel"} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
