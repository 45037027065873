import React from "react";
import PureComponent from "../../pure";
import sAction from 'sAction';
import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";
import Button from "../../formElements/Button";
import AcmDate from "../../formElements/AcmDate";

export default class SeekyAllActivities extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return <div style={{'overflow': 'auto'}}>
      <div className="templateCreatorFormContainer templateCreatorForm">
        <div className="templateCreatorFormRow" style={{'padding-top': '0'}} >
          <div dangerouslySetInnerHTML={{ __html: this.props.data?.get('data')?.get('text') }}/>
        </div>
      </div>
    </div>
    ;
  }
}
