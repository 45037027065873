import React, {PureComponent} from "react";
import ProductsTable from "ROOT/src/components/detailView/Accounts/ProductsTable";
import sAction from "sAction";

export default class DetailProductsTab extends PureComponent {
    render() {
        //vyrenderuje se pouze pokud je aktivni
        return (
            <>
                {
                    this.props.active ?
                        <div className="containerProductTable">
                            <ProductsTable data={this.props.data}/>
                        </div> : null
                }
            </>
        );
    }

}