/**
 * runAutosave
 * @param {string} prefix
 */
export default function runAutosave(prefix) {
    // check if prefix is root detailview
    if (prefix !== 'view') {
        return;
    }

    const module = this.dataGet(prefix + '/module');
    const changes = this.dataGet(prefix + '/changes');

    // check if save is needed
    if (!changes.get('fields').size &&
        !changes.get('customData').size &&
        !changes.get('files').size &&
        !changes.get('forceChange')
    ) {
        console.log('>> [INFO] No changes to save');
        return;
    }

    // check if all required fields are filed
    if (!this.requiredFieldsCheckActual(prefix)) {
        console.log('>> [INFO] Required fields not filled');
        this.toast({
            name: this.translate('LBL_REQUIRED_FIELDS_AUTOSAVE_ERROR'),
            description: ' ',
        });
        return;
    }

    // save record
    const saveData = {
        prefix: prefix,
        id: this.dataGet(`${prefix}/id`),
        module: module,
        canSave: true,
        type: this.dataGet(`${prefix}/type`),
        saveCallback: () => {},
    };

    this.saveRecord(saveData);
    // eslint-disable-next-line no-console
    console.log('>> [INFO] Autosave success');
    this.toast({
        name: this.translate('LBL_AUTOSAVE_SUCCESS'),
        description: ' ',
    });
}