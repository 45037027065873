import detailDefault from "../../detailDefault";

/** defaultni filtry chodi z backendu na polich Objednatel - firma a Zákazník - koncový, filrty jsou nastavene
 *  aby sla zadavat pouze centrala na polich Objednatel - firma a Zákazník - koncový
 */
export default class detailacm_orders extends detailDefault {
    load(sAction, data) {
        this.filterRelateOptions(sAction, data.prefix);

        sAction.dsClear();
        if (sAction.deviceType === 'desktop') {
            sAction.dsAdd('set', data.prefix + '/fields/contacts_acm_orders_1_name/def/required', false);
            sAction.dsAdd('set', data.prefix + '/fields/accounts_acm_orders_3_name/def/required', false);
            sAction.dsAdd('set', data.prefix + '/fields/acm_orders_accounts_name/def/required', true);
            sAction.dsAdd('set', data.prefix + '/fields/contacts_acm_orders_2_name/def/required', false);

        }

        let objednatelHqId = sAction.dataGet(data.prefix + 'fields/accounts_acm_orders_1_name/def/id_value');
        let zakaznikHqId = sAction.dataGet(data.prefix + 'fields/accounts_acm_orders_2_name/def/id_value');

        // if (objednatelHqId) { // uprava aby slo menit pole Objednatel - pobocka
            sAction.dsAdd('set', data.prefix + '/fields/accounts_acm_orders_3_name/def/readonly', false)
        // }
        if (zakaznikHqId) {
            sAction.dsAdd('set', data.prefix + '/fields/acm_orders_accounts_name/def/readonly', false)
        }

        let orderAccountId = sAction.dataGet(data.prefix + '/fields/accounts_acm_orders_1accounts_ida/value');
        if(orderAccountId) {
            sAction.dsAdd('set', data.prefix + '/fields/accounts_acm_orders_3_name/def/defaultFilter/0/value', orderAccountId);
            sAction.dsAdd('set', data.prefix + '/fields/accounts_acm_orders_3_name/def/readonly', false)
        }

        let customerAccountId = sAction.dataGet(data.prefix + '/fields/accounts_acm_orders_2accounts_ida/value');
        if(customerAccountId) {
            sAction.dsAdd('set', data.prefix + '/fields/acm_orders_accounts_name/def/defaultFilter/0/value', customerAccountId);
            sAction.dsAdd('set', data.prefix + '/fields/acm_orders_accounts_name/def/readonly', false)
        }

        sAction.dsProcess();

    }

    update(sAction, data) {
        this.filterRelateOptions(sAction, data.prefix);

        sAction.dsClear();
        let pobocka = ''
        const relType = sAction.dataGet(data.prefix + '/fields/' + data.field + '/def/rel_type')
        switch (data.field) {
            case "accounts_acm_orders_1accounts_ida":
                sAction.dsAdd('set', data.prefix + '/fields/accounts_acm_orders_3_name/def/readonly', false)
                sAction.dsAdd('set', data.prefix + '/fields/accounts_acm_orders_3_name/def/defaultFilter/0/value', data.value.id);
                pobocka = sAction.dataGet(data.prefix + 'fields/accounts_acm_orders_3_name/def/id_value');
                if (!pobocka) {
                    sAction.dsAdd('set', data.prefix + '/fields/contacts_acm_orders_1_name/def/defaultFilter/0/parentId', data.value.id);
                }

                if (data.value.id && relType) {
                    sAction.checkAccountRelType(data.value.id, relType, "accounts_acm_orders_1_name", data.prefix)
                }
                break;
            case "accounts_acm_orders_2accounts_ida":
                sAction.dsAdd('set', data.prefix + '/fields/acm_orders_accounts_name/def/readonly', false)
                sAction.dsAdd('set', data.prefix + '/fields/acm_orders_accounts_name/def/defaultFilter/0/value', data.value.id);
                pobocka = sAction.dataGet(data.prefix + 'fields/acm_orders_accounts_name/def/id_value');
                if (!pobocka) {
                    sAction.dsAdd('set', data.prefix + '/fields/contacts_acm_orders_2_name/def/defaultFilter/0/parentId', data.value.id);
                }

                if (data.value.id && relType) {
                    sAction.checkAccountRelType(data.value.id, relType, "accounts_acm_orders_2_name", data.prefix)
                }
                break;
            case 'accounts_acm_orders_3accounts_ida':
                if (data.value.id) {
                    sAction.dsAdd('set', data.prefix + '/fields/contacts_acm_orders_1_name/def/defaultFilter/0/parentId', data.value.id);
                } else {
                    pobocka = sAction.dataGet(data.prefix + 'fields/accounts_acm_orders_1_name/def/id_value');
                    sAction.dsAdd('set', data.prefix + '/fields/contacts_acm_orders_1_name/def/defaultFilter/0/parentId', pobocka);
                }

                if (data.value.id && relType) {
                    sAction.checkAccountRelType(data.value.id, relType, "accounts_acm_orders_3_name", data.prefix)
                }
                break;
            case 'acm_orders_accountsaccounts_ida':
                if (data.value.id) {
                    sAction.dsAdd('set', data.prefix + '/fields/contacts_acm_orders_2_name/def/defaultFilter/0/parentId', data.value.id);
                } else {
                    pobocka = sAction.dataGet(data.prefix + 'fields/accounts_acm_orders_2_name/def/id_value');
                    sAction.dsAdd('set', data.prefix + '/fields/contacts_acm_orders_2_name/def/defaultFilter/0/parentId', pobocka);
                }

                if (data.value.id && relType) {
                    sAction.checkAccountRelType(data.value.id, relType, "acm_orders_accounts_name", data.prefix)
                }
                break;
            case 'contacts_acm_orders_1contacts_ida':
                this.setContactData(sAction, data);
                break;
        }
        sAction.dsProcess();
        sAction.checkRelateErrors(data.prefix)
    }

    filterRelateOptions(sAction, prefix){
        // coripo/cemat#723 - 15580: Objednávka - políček prevest ze sevnisni zakazky na objednavky
        // Nastavi filtry dle coripo/cemat#223 - filtruje se v radcich stroje relate podle zakanzik - koncovy
        // a kontakty v Objednatel - osoba  podle Objednatel - osoba
        let zakFirma = sAction.dataGet(
            prefix + "/fields/acm_orders_accounts_name/def/id_value"
        );
        if(!zakFirma){
            zakFirma = sAction.dataGet(
                prefix + "/fields/acm_orders_accountsaccounts_ida/value"
            );
        }
        let objFirma = sAction.dataGet(
            prefix + "/fields/accounts_acm_orders_3_name/def/id_value"
        );
        if(!objFirma){
            objFirma = sAction.dataGet(
                prefix + "/fields/accounts_acm_orders_3accounts_ida/value"
            );
        }
        // let objFirma = sAction.dataGet(
        //     prefix + "/fields/accounts_acm_orders_1_name/def/id_value"
        // );
        // if(!objFirma){
        //     objFirma = sAction.dataGet(
        //         prefix + "/fields/accounts_acm_orders_1accounts_ida/value"
        //     );
        // }
        const defFilterMachines = [
          {
            module: "Accounts",
            name: "acm_machines_accounts_name",
            operandType: "relate",
            parentId: zakFirma,
            relName: "acm_machines_accounts",
            relationship: ["acm_machines_accounts"],
            type: "eq",
          },
        ];

        sAction.dsClear()

        const customData = sAction.dataGet(`${prefix}/customData`);
        if (customData == null) {
            // rightpanel drop fix
            console.error('No custom data for acm_order -> don\'t setup default filter');
            return;
        }

        sAction.dsAdd(
            "set",
            prefix +
            "/customData/customLines/moduleData/acm_machines_acm_orders_lines_1/defaultFilter",
            defFilterMachines
        );
        sAction.dsAdd(
            "set",
            prefix +
            "/fields/contacts_acm_orders_1_name/def/defaultFilter/0/parentId",
            objFirma
        );
        sAction.dsAdd(
            "set",
            prefix +
            "/customData/customLines/moduleData/acm_machines_acm_orders_lines_1_name/defaultFilter",
            defFilterMachines
        );
        sAction.dsProcess();
    }

    /**
     * Gets contact data from selected contact
     * @param {*} sAction
     * @param {*} data
     * @returns
     */
    setContactData(sAction, data) {
        const id = data?.value?.id;
        const phone = sAction.dataGet(data.prefix + '/fields/u_phone/value');
        const email = sAction.dataGet(data.prefix + '/fields/u_email_zakazka/value');
        const name = sAction.dataGet(data.prefix + '/fields/u_contact/value');
        // if (!id || (phone && email && name)) { // changed in coripo/cemat#724 to be able to change contact always
        if (!id) {
            return;
        }

        const searchData = {
            module: 'Contacts',
            fields: ['id'],
            value: id,
            getFields: ['phone_mobile', 'email1', 'full_name'],
        };
        sAction.quickSearch(searchData, returnData => {
            if(returnData.length > 0) {
                const contact = {
                    phone_mobile: returnData[0].phone_mobile ? returnData[0].phone_mobile : '',
                    email1: returnData[0].email1 ? returnData[0].email1 : '',
                    full_name: returnData[0].full_name ? returnData[0].full_name : '',
                };
                sAction.dsAdd('set', data.prefix + '/fields/u_phone/value', contact.phone_mobile);
                sAction.dsAdd('set', data.prefix + '/fields/u_email_zakazka/value', contact.email1);
                sAction.dsAdd('set', data.prefix + '/fields/u_contact/value', contact.full_name);

                sAction.dsAdd('set', data.prefix + '/changes/fields/u_phone', contact.phone_mobile);
                sAction.dsAdd('set', data.prefix + '/changes/fields/u_email_zakazka', contact.email1);
                sAction.dsAdd('set', data.prefix + '/changes/fields/u_contact', contact.full_name);
                sAction.dsProcess();
            }
        });

    }

}
