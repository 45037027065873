import React from "react";
import PureComponent from "../../pure";

import Select from "../../formElements/Select";

import ReportWindowGroup from "./ReportWindowGroup";

import  sAction  from "sAction";

class ReportWindowContent extends PureComponent {
    constructor(props) {
        super(props);
        try {
            this.state = {
                optionsRender: sAction.dataGet("reportWindow/sortedPairs").toJS()
            }
        }
        catch (e){
            console.log(e)
        }

    }

  render() {
    var data = this.props.data;
    var way = "reportWindow";

    var saveSearchOptions = [{ value: "", label: "" }];
    data.savedSearch.forEach(search => {
      saveSearchOptions.push({
        value: search.get("id"),
        label: search.get("name")
      });
    });
    return (
      <div className="reportWindowContent">
        <div className="reportContentLine">
            <div className="reportWindowCellAlign reportWindowModuleSelect">
                <div className="reportContentLineCellText" >
                    <div>{sAction.translate("LBL_REPORT_SEARCH")}</div>
                </div>
                <div className="reportContentLineCelSelect" style={{width: "300px"}}>
                    <Select
                        containerClassName="withBorder"
                        options={this.state.optionsRender}
                        defaultValue={data.module}
                        onChange={e => sAction.reportSelectMainModule(e.target.value)}
                    />
                </div>
          </div>
          {data.module != null && (
              <div className="reportWindowCellAlign reportWindowSelectSearch">
                  <div className="reportContentLineCellText">
                      <div>{sAction.translate("LBL_REPORT_SAVED_FILTERS")}</div>
                  </div>
                  <div className="reportContentLineCelSelect">
                      <Select
                          defaultValue={data.metadata != null ? data.metadata.id : ""}
                          containerClassName="withBorder"
                          options={saveSearchOptions}
                          onChange={e => {
                              sAction.load();
                              sAction.clearPreviousOrderBy();
                              sAction.reportWindowLoadSearch(data.module, e.target.value);
                          }}
                      />
                  </div>
              </div>
          )}
        </div>
        <div className="reportWindowContentPlace">
          <div
            className={
              data.filterAlert.get("alert") === true
                ? "reportWindowFilterMessage"
                : "reportWindowFilterMessage unvisible"
            }
            onClick={() =>
              sAction.dataSet("reportWindow/filterAlert/alert", false)
            }
          >
            {data.filterAlert.get("message")}
            <div className="closeMessage deleteIconWhite" />
          </div>

          {data.group != null && (
            <ReportWindowGroup
              data={data.group}
              fields={data.fields}
              relate={data.relate}
              module={data.module}
              way={way + "/group"}
            />
          )}
        </div>
      </div>
    );
  }
}
export default ReportWindowContent;
