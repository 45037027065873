import React from 'react';
import sAction from 'sAction';
import SimpleFormFromArray from '../../formElements/SimpleFormFromArray';

export default function AccessRequestDenyReasonPopup(props) {
    const buttons = {
        cancel: {
            checkRequired: false,
            onClick: () => {
                sAction.popupHide();
            },
            returnButtonName: false,
            label: sAction.translate('LBL_CANCEL'),
        },
        send: {
            checkRequired: true,
            onClick: (data) => {
                sAction.accessRequestDeny({
                    id: props.data.get('params').get('id'),
                    reason: data.reason.target.value,
                    status: props.data.get('params').get('status'),
                    sendReason: data.sendReason,
                });
            },
            returnButtonName: false,
            label: sAction.translate('LBL_CONTINUE'),
        },
    };

    const fields = {
        reason: {
            type: 'textarea',
            required: true,
            label: sAction.translate('LBL_ACCESS_REQUEST_DENY_REASON', props.data.get('params').get('module')),
        },
        sendReason: {
            type: 'checkbox',
            required: false,
            label: sAction.translate('LBL_ACCESS_REQUEST_DENY_REASON_SHARE', props.data.get('params').get('module')),
        },
    };
    return (
        <SimpleFormFromArray
            buttons={buttons}
            fields={fields}
        />
    );
}
