export default function portalUserSetAsAdmin(params) {
    this.confrim(this.translate('LBL_SET_AS_ADMIN_CONFIRM', 'acm_portal_users'), () => {
        this.popupHide();
        this.load();

        const data = {
            'newState': true,
            'userID': params.id,
        };

        this.rest.post('portalUserSwitchAdmin', {data}, (resp) => {
            this.unLoad();
            if (resp.status) {
                this.route();
            } else {
                this.error(resp.errorMessage.text);
            }
        });
    });
}
