export default function isDuplicateSearch(searchName){
    const savedSearches = this.dataGet("reportWindow/savedSearch")
    const currentUserId = this.dataGet("conf/user/id")
    if(savedSearches){
        return savedSearches.some(savedSearch => {
            return savedSearch?.get("assigned_user") === currentUserId && savedSearch?.get("name_without_user") === searchName
        })

    }

}