import React from 'react'
import PureComponent from '../../pure'
import sAction from "sAction";
import Map from "immutable"

export default class StockLine extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showOptions: false,
            style: {}
        }
        this.setMaxValues();
    }

    componentDidMount() {
        this.setMaxValues();
        window.addEventListener('resize', () => {
            this.setMaxValues();
            this.setState({
                style: {
                    top: parseInt(this.state.style.top) - 25 + 'px',
                    left: parseInt(this.state.style.left) - 170 + 'px',
                }
            })
        })
    }

    setMaxValues() {
        this.maxTop = window.innerHeight - 300;
        this.maxLeft = window.innerWidth - 250;
        this.minTop = 60;
        this.minLeft = 60;
    }

    showStockOptions(event) {
        let top = (event.pageY - 100) > this.maxTop ? this.maxTop : event.pageY - 100;
        let left = (event.pageX - 410) > this.maxLeft ? this.maxLeft : event.pageX - 410;
        if (top < this.minTop) {
            top = this.minTop;
        }
        if (left < this.minLeft) {
            left = this.minLeft;
        }
        this.setState({
            showOptions: true,
            style: {
                top: top + 'px', 
                left: left + 'px',
            },
            id: 'adicon_box'
        })
    }

    getColor(mainStock) {
        const stockState = parseFloat(mainStock)
        switch (true) {
            case stockState <= 0:
                return "isNotInStock"
            case stockState <= 3:
                return "lowOnStock"
            case stockState >= 4:
                return "isInStock"
            default:
                return "stockStateUnknown"
        }
    }

    createStockOptions(stockOptions, currentStock, stockRezervace) {
        if (!stockOptions.current_stock) {
            return;
        }

        let tableRows = []
        let rezervaceCells = (
            <>
                <td key={0} style={{borderBottom: "none"}} className="stockStateNameReservation"/>
                <td key="empty"/>
            </>
        );
        stockOptions.current_stock.forEach((stock, index) => {
            let previousRezervace = index - 1
            let rezervaceName = sAction.translate(stockOptions.stock_rezervace[previousRezervace]?.lbl)
            if (rezervaceName && index !== 0) {
                let rezervaceValue = stockRezervace[stock.name + "_rezervace"]
                if (!rezervaceValue) {
                    rezervaceValue = "0.00"
                }

                rezervaceCells = (
                    <>
                        <td className="stockStateNameReservation" key={stockOptions.stock_rezervace[previousRezervace]}>
                            {sAction.translate(stockOptions.stock_rezervace[previousRezervace]?.lbl)}
                        </td>
                        <td className="stockStateReservationNumber" key={stockRezervace[stock.name + "rezervace"]}>
                            {rezervaceValue}
                        </td>
                    </>)
            }

            let currStockValue = currentStock[stock.name]
            if (!currStockValue) {
                currStockValue = "0.00"
            }
            tableRows.push(
                <tr key={stock.name} className="stockLine">
                    <td className="stockStateName" key={stock.name}>{sAction.translate(stock.lbl)}</td>
                    <td className="stockStateNumber"
                        key={currentStock[stock.name]}>{currStockValue}</td>
                    {rezervaceCells}
                </tr>
            )
        })

        return (
            <table className="stockStateTable">
                <thead>
                <tr>
                    <th className="stockTableHeaderCell" colSpan={2}>{sAction.translate("LBL_CURRENT_STOCK_NUMBER")}</th>
                    <th className="stockTableHeaderCell" colSpan={2}>{sAction.translate("LBL_RESERVATION_STOCK_NUMBER")}</th>
                </tr>
                </thead>
                <tbody>{tableRows}</tbody>
            </table>
        )
    }

    getStockQuantity(stock) {
        if (!stock) {
            return
        }

        if (parseFloat(stock) >= 100) {
            return "99+"
        }

        return Math.ceil(parseFloat(stock))
    }

    render() {
        let currentStock = this.props.productData.current_stock
        let stockRezervace = this.props.productData.stock_rezervace

        if (Map.isMap(this.props.productData.current_stock)) {
            currentStock = this.props.productData.current_stock.toJS()
        }
        if (Map.isMap(this.props.productData.stock_rezervace)) {
            stockRezervace = this.props.productData.stock_rezervace.toJS()
        }

        let lineRender = (
            <span className={"stockSquare " + this.getColor(currentStock.stock)}
                  style={{borderRadius: 0}}>
                {this.getStockQuantity(currentStock.stock)}
            </span>
        );

        let popup = null
        if (this.state.showOptions) {
            let stockOptionsTable = this.createStockOptions(this.props.stockOptions, currentStock, stockRezervace)

            popup = (<div className="stockPopup" style={this.state.style}>
                    <div className="calAdiconHeader">
                        <span className="calAdiconBoxTitle">{sAction.translate("LBL_STOCK_STATE")}</span>
                    </div>
                    <div className="stockOptionsContainer">{stockOptionsTable}</div>
                </div>
            )
        }

        return (
            <div
                className={"newListViewLine " + this.props.extraClass}
                onMouseEnter={(e) => this.showStockOptions(e)}
                onClick={(e) => this.showStockOptions(e)}
                onMouseLeave={() => this.setState({showOptions: false})}
            >
                {lineRender}
                {this.state.showOptions ? popup : null}
            </div>
        );
    }

}