export default function portalUserUnsetAsAdmin(params) {
    this.confrim(this.translate('LBL_UNSET_AS_ADMIN_CONFIRM', 'acm_portal_users'), () => {
        this.popupHide();
        this.load();

        const data = {
            'newState': 0,
            'userID': params.id,
        };

        this.rest.post('portalUserSwitchAdmin', {data}, (resp) => {
            this.unLoad();
            if (resp.status) {
                location.reload();
            } else {
                this.error(resp.errorMessage.text);
            }
        });
    });
}
