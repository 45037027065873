import detailDefault from "../../detailDefault";

export default class detailacm_service_order extends detailDefault {
  afterSave(sAction, data) {
    if (sAction.deviceType == "servis") {
      sAction.updateServisInfo();
      sAction.toast({
        name: "Uloženo",
      });
    }
  }
  load(sAction, data) {
    this.filterRelateOptions(sAction, data.prefix);
    this.checkOrderType(sAction, data.prefix)
    const disabledOptionsPosition = ["solved"];
    sAction.disableEnumOptions(
      data.prefix,
      "stav",
      "campaign_state_list",
      disabledOptionsPosition
    );

    if(sAction.dataGet(`${data.prefix}/fields/longterm_deadline/value`)){
        sAction.dataSet(`${data.prefix}/fields/acm_service_order_accounts_name/def/required`, false)
    }

    if (sAction.dataGet(data.prefix + "/fields/locked/value") === "1") {
      sAction.dataSet(data.prefix + "/readonly", true);
    }

    this.getMachinePhotosStatus(sAction, data.prefix);

    if (sAction.deviceType == "servis") {
      this.serviceLockFields(sAction, data);

      const servisStatus = sAction.dataGet("appViewCustomData/servisStatus");
      const actOrder = servisStatus.getIn(["activeOrder", "orderId"]);
      const pause = servisStatus.get("pause");

      const order = sAction.dataGet(data.prefix + "/id");

      sAction.dataAdd(
          data.prefix + "/buttons",
          {
            id: "cancelButton",
            def: {
              onClick: "cancelServiceOrder",
              label: "LBL_CANCEL_SERVICE_ORDER",
              id: "cancelButton",
              iconClass: "cancelCircle",
            },
          },
          "DetailButton"
      );

      if (actOrder == order && !pause) {
        sAction.dataAdd(
            data.prefix + "/buttons",
            {
              id: "pauseButton",
              def: {
                onClick: "startPause",
                label: "LBL_START_PAUSE",
                id: "pauseButton",
                iconClass: "pause",
              },
            },
            "DetailButton"
        );
        return
      }

      sAction.dataAdd(
          data.prefix + "/buttons",
          {
            id: "pauseButton",
            def: {
              onClick: "stopPause",
              label: "LBL_STOP_PAUSE",
              id: "pauseButton",
              iconClass: "pause",
            },
          },
          "DetailButton"
      );
    }
  }

  update(sAction, data) {
    this.filterRelateOptions(sAction, data.prefix);
    const arr = [
      "date_work_end",
      "respon_person",
      "respon_sign",
      "respon_not_found",
    ];

    if (data.field === "locked" && data.value === 1) {
      this.makePopup(sAction, data.prefix);
    }

    if (arr.includes(data.field) && this.canLock(sAction, data.prefix)) {
      this.makePopup(sAction, data.prefix);
    }

    if(data.field === "canceled_trip"){
      console.log(data.value)
      if(data.value == 1){
        this.canceledTrip(sAction, data)
      }
    }

    if(data.field === "acm_service_order_lines_acm_machinesacm_machines_ida"){
      sAction.rest.post("getMachineMotohours", data.value, returnData => {
        if(returnData.status) {
          this.updateMotohours(returnData.message.data, data.value, data.prefix, sAction);
        }
      }, false)
    } else if (data.field === "order_type"){
      this.checkOrderType(sAction, data.prefix)
    } else if (data.field === "longterm_deadline") {
      if (data.value) {
        sAction.dataSet(`${data.prefix}/fields/acm_service_order_accounts_name/def/required`, false)
      } else {
        sAction.dataSet(`${data.prefix}/fields/acm_service_order_accounts_name/def/required`, true)
      }
    } else if (data.field === "respon_person" && data.value){
      sAction.dataSet(`${data.prefix}/fields/customer_person/value`, data.value)
      sAction.dataSet(`${data.prefix}/changes/fields/customer_person`, data.value)
    }
  }

  updateOrderState(sAction, prefix, valueOfState) {
    sAction.dataSet(prefix + "/fields/stav/value", valueOfState);
    sAction.dataSet(prefix + "/changes/fields/stav", valueOfState);
  }

  /**
   * kontroluje jestli se muze zamknout
   * responNotFound ze storu vraci budto undefined nebo "0". value && "0" je podle js true. Takze se musi
   * responNotFound checkovat na === 1. Pokud je vyplnen podpis(responSign) nesmi se kontrolovat responNotFound
   * @param sAction
   * @param prefix
   * @returns {boolean}
   */
  canLock(sAction, prefix) {
    const orderState = sAction.dataGet(prefix + "/fields/stav/value")
    const dateWorkEnd = sAction.dataGet(prefix + "/fields/date_work_end/value");
    const responPerson = sAction.dataGet(
      prefix + "/fields/respon_person/value"
    );
    const responSign = sAction.dataGet(prefix + "/fields/respon_sign/value");
    const responNotFound = sAction.dataGet(
      prefix + "/fields/respon_not_found/value"
    );

    if (orderState === "new" || orderState === "in_progress" || orderState === "scheduled") {
      if (responSign) {
        return !!(dateWorkEnd && responPerson);
      } else if (responNotFound === 1) {
        return !!(dateWorkEnd && responPerson);
      }
    }

  }

  makePopup(sAction, prefix) {
    const data = {
      header: sAction.translate("LBL_LOCK_DETAIL"),
      buttons: [
        {
          label: sAction.translate("LBL_CLOSE_POPUP"),
          callback: () => {
            sAction.dsClear();
            sAction.dsAdd("set", prefix + "/fields/locked/value", 0);
            sAction.dsAdd("set", prefix + "/changes/fields/locked", 1);
            sAction.dsProcess();
            sAction.popupHide();
          },
        },
        {
          label: sAction.translate("LBL_LOCK_DETAIL"),
          callback: () => {
            sAction.popupHide();
            this.lockDetail(sAction, prefix);
            this.prepareSave(sAction, prefix);
          },
        },
      ],
    };

    sAction.popup(sAction.translate("LBL_LOCK_PHRASE"), data);
  }

  lockDetail(sAction, prefix) {
    sAction.dsClear();
    sAction.dsAdd("set", prefix + "/readonly", true);
    sAction.dsAdd("set", prefix + "/fields/locked/value", 1);
    sAction.dsAdd("set", prefix + "/changes/fields/locked", 1);
    sAction.dsProcess();
  }

  prepareSave(sAction, prefix) {
    const saveData = {
      canSave: true,
      id: sAction.dataGet(prefix + "/id"),
      module: sAction.dataGet(prefix + "/module"),
      prefix: prefix,
      type: sAction.dataGet(prefix + "/type"),
      view: sAction.dataGet("conf/view"),
    };

    sAction.saveRecord(saveData);
  }
  serviceLockFields(sAction, data) {
    sAction.dsClear();
    const lockedFields = [
      "stav",
      "order_type",
      "name",
      "acm_service_order_accounts_1_name",
      "exec_address",
      "acm_service_order_contacts_1_name",
      "customer_phone",
      "customer_email",
      "date_work_begin",
      "date_work_end",
      "locked",
    ];
    lockedFields.forEach((field) => {
      sAction.dsAdd(
        "set",
        data.prefix + "/fields/" + field + "/def/readonly",
        true
      );
    });
    sAction.dsProcess();
  }

  updateMotohours(motohours, seekedLineLinkId, prefix, sAction) {
    const lines = sAction.dataGet(prefix + "/customData/customLines/lines");

    lines.forEach((line, index) => {
      if (id === seekedLineLinkId){
        sAction.dataSet(prefix + "/customData/customLines/lines/" + index + "/motohours", motohours)
      }
    })

  }

  filterRelateOptions(sAction, prefix){
    // Nastavi filtry dle coripo/cemat#223 - filtruje se v radcich stroje relate podle zakanzik - koncovy
    // a kontakty v Objednatel - osoba  podle Objednatel - osoba
    let zakFirma = sAction.dataGet(
        prefix + "/fields/acm_service_order_accounts_1_name/def/id_value"
    );
    let objFirma = sAction.dataGet(
        prefix + "/fields/acm_service_order_accounts_name/def/id_value"
    );
    let zakFirmaPobocka = sAction.dataGet(
      prefix + "/fields/acm_service_order_accounts_2_name/def/id_value"
    );

    if(!zakFirma){
      zakFirma = sAction.dataGet(
          prefix + "/fields/acm_service_order_accounts_1accounts_ida/value"
      );
    }
    if(!objFirma){
      objFirma = sAction.dataGet(
          prefix + "/fields/acm_service_order_accountsaccounts_ida/value"
      );
    }
    if(!zakFirmaPobocka){
      zakFirmaPobocka = sAction.dataGet(
        prefix + "/fields/acm_service_order_accounts_2accounts_ida/value"
      );
    }
  
    const defFilterMachines = [
      {
        module: "Accounts",
        name: "acm_machines_accounts_name",
        operandType: "relate",
        parentId: zakFirmaPobocka ? zakFirmaPobocka : zakFirma,
        relName: "acm_machines_accounts",
        relationship: ["acm_machines_accounts"],
        type: "eq",
      },
    ];
    const filterUserTechnicians = [
      {
        type: "eq",
        value: 1,
        field: "technician"
      }
    ]

    sAction.dsClear()
    sAction.dsAdd(
        "set",
        prefix +
        "/customData/customLines/moduleData/acm_service_order_lines_acm_machines/defaultFilter",
        defFilterMachines
    );
    sAction.dsAdd(
        "set",
        prefix +
        "/fields/acm_service_order_contacts_name/def/defaultFilter/0/parentId",
        objFirma
    );
    sAction.dsAdd(
        "set",
        prefix +
        "/customData/customLines/moduleData/acm_service_order_lines_acm_machines_name/defaultFilter",
        defFilterMachines
    );
    sAction.dsAdd(
        "set",
        prefix +
        "/fields/assigned_user_name/def/defaultFilter",
        filterUserTechnicians
    );

    let filters = [];
    if (sAction.isServis()) {
      filters = [{
        field: "typ_servis",
        type: "eq",
        value: this.getProductFilterByType(sAction, prefix),
      }]

      //V servisnim view lze do SZ pridavat pouze prodkuty s u_servis_crm = 1. Které produkty to jsou bylo dodano Cematem
      if (sAction.deviceType === "servis") {
        filters.push({field: "u_servis_crm", type: "eq", value: 1,})
      }
    }

    sAction.dsAdd(
        "set",
        prefix +
        "/customData/customLines/moduleData/name/defaultFilter",
        filters
    );
    sAction.dsProcess();
  }

    checkOrderType(sAction, prefix) {
        if (sAction.dataGet(`${prefix}/fields/order_type/value`) === "internal_service") {
            sAction.dsClear()
            sAction.dsAdd("set", `${prefix}/fields/customer_email/def/required`, false)
            sAction.dsAdd("set", `${prefix}/fields/customer_phone/def/required`, false)
            sAction.dsProcess();
            return
        }
        sAction.dsClear()
        sAction.dsAdd("set", `${prefix}/fields/customer_email/def/required`, true)
        sAction.dsAdd("set", `${prefix}/fields/customer_phone/def/required`, true)
        sAction.dsProcess();

    }

  updateDetailLine(sAction, data) {
    switch (data.field) {
      case 'name':
        if (data.value.id) {
          let fields = {
            quick: 'typ_quick',
          };
          const searchData = {
            module: 'ProductTemplates',
            fields: ['id'],
            getFields: Object.values(fields),
            value: data.value.id,
            limit: 999999,
          };

         this.doQuickSearch(sAction, data, searchData, fields)
        }
        break;
      case 'acm_service_order_lines_acm_machinesacm_machines_ida':
        if (data.value) {
          let fields = {
            front_pneu_type: 'front_pneu_quickstandard',
            rear_pneu_type: 'rear_pneu_quickstandard',
          };
          const searchData = {
            module: 'acm_machines',
            fields: ['id'],
            getFields: Object.values(fields),
            value: data.value,
            limit: 999999,
          };

          this.doQuickSearch(sAction, data, searchData, fields)
        }
    }
  }

  doQuickSearch(sAction, data, searchData, fields){
    sAction.load();
    sAction.quickSearch(searchData, returnData => {
      sAction.dsClear();
      fields.forEachObject((templateName, lineName) => {
        data.saveField(returnData[0][templateName], lineName, true);
      });
      sAction.dsProcess();
      sAction.unLoad();
    });
  }

  getProductFilterByType(sAction, prefix) {
      switch (sAction.dataGet(prefix + "/fields/order_type/value")) {
          case "pneu_service":
              return 1;
          case "pz_service":
              return 2;
          case "otr_service":
              return 3;
          case "internal_service":
              return 1;
              // return 4; zmena z issue coripo/cemat#745 - interní zakázka, aby mohli servisní mechanici vybírat se stejných karet jako je služba pneu
          default:
              return 0
      }
  }

  saveValueCustomLine(key, value, way, index, sAction) {
    sAction.dsClear();
    sAction.dsAdd("set", way + '/customData/customLines/lines/' + index + '/' + key, value);
    sAction.dsAdd("set", way + '/changes/forceChange', true);
    sAction.dsProcess();
  }

  canceledTrip(sAction, data) {
    sAction.confrim(sAction.translate("LBL_SET_TRIP_AS_CANCELED_TRIP", "acm_service_order"), () => {

      const saveData = {
        canSave: true,
        id: sAction.dataGet(data.prefix + "/id"),
        module: sAction.dataGet(data.prefix + "/module"),
        prefix: data.prefix,
        type: sAction.dataGet(data.prefix + "/type"),
        view: sAction.dataGet("conf/view"),
      };
      
      sAction.popupHide();
      sAction.saveRecord(saveData);
    })
  }

  /**
   * Zjistuje jestli je u strojů v detaillines nahraná fotka a podle toho nastavuje ikonku
   * @param sAction
   * @param prefix
   */
  getMachinePhotosStatus(sAction, prefix) {
    // if view is not servis return
    if (sAction.deviceType !== "servis") {
      return;
    }
    const lines = sAction.dataGet(prefix + "/customData/customLines/lines");
    let photosStatus = false;
    const machineIds = [];

    lines.forEach((line) => {
      const id = line.get("acm_service_order_lines_acm_machinesacm_machines_ida");
      if (id) {
        if(!machineIds.includes(id)){
          machineIds.push(id)
        }
      }
    });

    // call to rest getMachinesPhotoStatus post with machineIds
    sAction.rest.post("getMachinesPhotoStatus", machineIds, returnData => {
      if(returnData.status) {
        const photosStatus = returnData.message.data;
        this.setMachinePhotosStatus(sAction, prefix, photosStatus);
      }
    }, false)
  }

  setMachinePhotosStatus(sAction, prefix, photosStatus) {
    const lines = sAction.dataGet(prefix + "/customData/customLines/lines");

    lines.forEach((line, index) => {
      const id = line.get("acm_service_order_lines_acm_machinesacm_machines_ida");
      if (id) {
        const status = photosStatus[id];
        sAction.dataSet(prefix + "/customData/customLines/lines/" + index + "/photosStatus", status)
      }
    })
  }

}
