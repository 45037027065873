
import detailDefault from '../../detailDefault';
export default class detailacm_portal_access_requests extends detailDefault {
  load(sAction, data) {
    // call immediately after detailView is load
    this.updateReasonFieldDefs(sAction, data.prefix, sAction.dataGet(data.prefix + '/fields/stage/value'));
  }
  update(sAction, data) {
    // call immediately after detailView field is update
    if (data.field === 'stage') {
      this.updateReasonFieldDefs(sAction, data.prefix, data.value);
    }
  }

  // rejection reason must be filled if rejected
  updateReasonFieldDefs(sAction, prefix, stage) {
    sAction.dsClear();
    sAction.dsAdd('set', prefix + '/fields/reject_reason/def/visible', stage !== 'accepted');
    sAction.dsAdd('set', prefix + '/fields/reject_reason/def/readonly', stage === 'new');
    sAction.dsAdd('set', prefix + '/fields/reject_reason/def/required', stage === 'rejected');
    sAction.dsProcess();
  }
}
