import $ from 'jquery';
import moment from 'moment';
import sAction from 'sAction';
import {Map} from 'immutable';

export default class rest {
    constructor(parent) {
        this.url = parent.param.server;
        this.token = null;
        this.parent = parent;
        this.xhr = null;
    }

    post(action, data, complete, cancelPrevisouRequest = true) {
        const self = this;
        this.call(
            action,
            'POST',
            data,
            function(msg) {
                self.logRequest({action, data}, msg, cancelPrevisouRequest);
                complete(msg);
            },
            cancelPrevisouRequest,
        );
    }
    get(action, complete, cancelPrevisouRequest = true) {
        const self = this;
        this.call(
            action,
            'GET',
            null,
            function(msg) {
                self.logRequest({action}, msg, cancelPrevisouRequest);
                complete(msg);
            },
            cancelPrevisouRequest,
        );
    }
    delete(action, data, complete) {
        this.call(action, 'DELETE', data, function(msg) {
            complete(msg);
        });
    }

    call(action, method, data, complete, cancelPrevisouRequest) {
        const auth = this.getAuth();
        const self = this;
        if (this.xhr != null && cancelPrevisouRequest == true) {
            this.xhr.abort();
        }

        if (this.parent?.param?.xdebugEnabled) {
            action += (action?.includes('?') ? '&' : '?') + 'XDEBUG_SESSION_START=1';
        }

        this.xhr = $.ajax({
            method: method,
            contentType: 'application/json',
            url: self.url + '/' + action,

            data: JSON.stringify(data),
            headers: auth,
            success: (data) => {
                // zobrazeni fatalnejsich chyb, ktere mohou prerusit routovani atd.
                if (typeof data === 'object' && data.status === 'error') {
                    if (data.reason != undefined) {
                        self.parent.dsClear();
                        if (self.parent.dataGet('rightPanel/show') == true) {
                            self.parent.dsAdd('set', 'rightPanel/content', 'error');
                            self.parent.dsAdd('set', 'conf/load', false);
                            self.parent.dsAdd('set', 'rightPanel/data', {
                                state: false,
                                type: data.reason,
                            });
                        } else {
                            self.parent.dsAdd('set', 'conf/view', 'error');
                            self.parent.dsAdd('set', 'conf/load', false);
                            self.parent.dsAdd('set', 'view', {
                                state: false,
                                type: data.reason,
                            });
                        }

                        self.parent.dsProcess();
                    } else {
                        self.parent.unLoad();
                        self.parent.error(self.parent.translate(data.msg || data.message));
                    }
                }

                complete(data);

                // zobrazeni dalsich chybovych hlasek
                if (typeof data === 'object' && data.status === 'warning') {
                    setTimeout(function() {
                        self.parent.error(self.parent.translate(data.msg || data.message));
                    }, 2500);
                }
            },
            error: (XMLHttpRequest, textStatus, errorThrown) => {
                if (
                    (XMLHttpRequest.status == '401' || XMLHttpRequest.status == 0) &&
          XMLHttpRequest.statusText != 'abort'
                ) {
                    if (action === 'getNotifyCount') {
                        console.log('getNotifyCount error', XMLHttpRequest);
                        if (XMLHttpRequest.status == '401') {
                            self.parent.dataSet('conf/loggedOut', true);
                        } else if (XMLHttpRequest.status == 0) {
                            this.userOffline();
                        }
                        return;
                    }
                    if (action === 'recalcTopRecent') {
                        return;
                    }

                    if (!window.location.hash.startsWith('#login')) {
                        if (XMLHttpRequest.status == '401') {
                            window.location.hash = '#login';

                            this.get('trns', (data) => {
                                self.parent.app_strings = data.lang;
                                self.parent.renderReact();
                            });
                        } else {
                            self.parent.error(self.parent.translate('LBL_SERVER_ERROR'));
                            self.parent.unLoad();
                        }
                    } else {
                        clearInterval(this.parent.notificationInterval);
                        self.parent.routeLogin();
                    }
                } else {
                    if (XMLHttpRequest.status === 500) {
                        complete(XMLHttpRequest?.responseJSON?.error);
                    }
                    if (action === 'getNotifyCount') {
                        this.userOffline();
                        return;
                    }
                    // throw new Error("Error: " + XMLHttpRequest.status);
                }
            },
        });
    }
    getAuth() {
        const token = this.getCookie('sID');
        const auth = {
            sID: token,
        };

        if (this.parent.deviceType != undefined) {
            auth['device'] = this.parent.deviceType;
        }

        return auth;
    }
    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + '; ' + expires;
    }
    getCookie(cname) {
        const name = cname + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return null;
    }

    logRequest(request, response, cancelPrevisouRequest) {
        if (request.action === 'getNotifyCount' ||
      request.action === 'logError' ||
      request.action === 'getErrorLogs' ||
      request.action === 'getErrorLogDetail') {
            return;
        }
        let parsedResponse = response;

        if (typeof response === 'object' || Array.isArray(response)) {
            const stringResponse = JSON.stringify(response);
            if (stringResponse.length > 100) {
                parsedResponse = this.scrapeJson(response, 3);
            }
        }

        let networkStack = localStorage.getItem('networkStack');
        const requestObj = {
            request: request,
            response: parsedResponse,
            cancelPR: cancelPrevisouRequest,
            time: moment().format('YYYY-MM-DD HH:mm:ss'),
            url: this.url,
        };

        if (networkStack) {
            networkStack = JSON.parse(networkStack);
        } else {
            networkStack = [];
        }

        if (networkStack.length > 5) {
            networkStack.shift();
        }
        if (networkStack.length > 6) {
            networkStack = [];
        }
        networkStack.push(requestObj);
        localStorage.setItem('networkStack', JSON.stringify(networkStack));
    }

    /**
    * Scrape and reduce JSON object
    * depth sets maximum depth of recursion
    * everything deeper is replace with "..."
    * level is depth +1 (depth 0 -> only 1 layer stays)
    * if key has more than 20 children, it is replaced with "..."
    * @param {Object} object
    * @param {Number} depth
    * @return {Object}
  */
    scrapeJson(object, depth = 3, level = 0) {
        if (depth < 0 && typeof object === 'object') {
            return '..';
        }
        if (object && typeof object === 'object') {
            let keyCount = 0;
            // let keyCount = Object.keys(object).length;
            return Object.keys(object).reduce((acc, key) => {
                keyCount++;
                const keyPrefix = key.substring(0, 4);
                if ( keyPrefix === 'LBL_' ||
          keyPrefix === 'ERR_' ||
          keyPrefix === 'LNK_' ||
          keyPrefix === 'MSG_'
                ) {
                    return acc;
                }

                if (keyCount > 20 && level > 0) {
                    acc['...'] = '..';
                    return acc;
                }
                acc[key] = this.scrapeJson(object[key], depth - 1, level + 1);
                return acc;
            }, {});
        }
        return object;
    }

    /**
     * @TODO: refine comments!
     * Metoda pocita s formatem dat v coripo standardu, jinak nebude fungovat spravne.
     * @param {string} action
     * @param {string} method
     * @param {null|any} data
     * @param {boolean} cancelPreviousRequest
     * @return {Promise<any>}
     */
    async fetchData(action, method, data = null, cancelPreviousRequest = true) {
        const self = this;
        const sId = this.getAuth();
        let url = self.url + '/' + action;
        if (this.parent?.param?.xdebugEnabled) {
            url += (url?.includes('?') ? '&' : '?') + 'XDEBUG_SESSION_START=1';
        }

        // if (this.controller && cancelPreviousRequest) {
        //     this.controller.abort();
        // }

        // this.controller = new AbortController();

        const fetchParams = {
            method: method,
            contentType: 'application/json',
            // signal: this.controller.signal,
            headers: new Headers({
                'content-type': 'application/json',
                'sId': sId.sID,
                'device': self.parent.deviceType,
            }),
        };

        if (['POST', 'PUT', 'PATCH'].includes(method) && data) {
            fetchParams.body = JSON.stringify(data);
        } else if (data && typeof data === 'object' && data !== {}) {
            // encode data to url query string for methods that don't support body
            // immutable Map used so object can be treated as associative array
            url += (url?.includes('?') ? '&' : '?') + (new Map(data)).map((value, param) => {
                // if value is array, add multiple params with same name and [] at the end
                if (Array.isArray(value)) {
                    return value.map((value) => {
                        return encodeURIComponent(param) + '[]=' + encodeURIComponent(value);
                    }).join('&');
                }

                return encodeURIComponent(param) + '=' + encodeURIComponent(value);
            }).join('&');
        } else if (data && data !== {}) {
            console.error(`Unsupported data of type ${typeof data} for method ${method}`);
        }

        const fetchedData = await fetch(url, fetchParams)
            .then((response) => response.json())
            .then((result) => result)
            .catch((responseError) => {
                switch (responseError.name) {
                    case 'AbortError':
                        console.warn(`Request ${action} canceled`);
                        break;
                    default:
                        this.parent.unLoad();
                        console.error('Error', responseError);
                        console.warn('You propably have error in your BE method returned data is not valid JSON');
                }
            });

        if (!fetchedData?.status) {
            throw fetchedData?.errorMessage;
        }

        return fetchedData.message;
    }


    async getAuthMS() {
        let token = sAction.dataGet('conf/user/microservice_access_token');
        let expiresAt = sAction.dataGet('conf/user/microservice_access_token_expires_at');
        // let allowedScopes = sAction.dataGet('conf/user/microservice_access_token_allowed_scopes');
        const now = new Date();
        const expires = new Date(expiresAt);
        if (!token || expires < now) {
            const data = await this.fetchData('CoripoMicroservices/getAccessToken', 'GET');
            token = data?.data?.accessToken ?? '';
            expiresAt = data?.data?.accessToken ?? '';
            sAction.dataSet('conf/user/microservice_access_token', token);
            sAction.dataSet('conf/user/microservice_access_token_expires_at', expiresAt);
        }
        return {
            'Authorization': `Bearer ${token}`,
        };
    }

    async fetchMS(action, method, data = null, cancelPreviousRequest = true) {
        const msURL = sAction.dataGet('conf/user/microservice_url');
        if (!msURL) {
            sAction.error('Missing microservice url');
            return {data: {}};
        }
        let getParams = '';

        const headers = {
            ...await this.getAuthMS(),
            'Content-Type': 'application/json',
        };

        const config = {
            method,
            contentType: 'application/json',
            headers: headers,
            // signal: todo
        };

        if (['GET'].includes(method)) {
            getParams += (getParams?.includes('?') ? '&' : '?') + new URLSearchParams(data);
        } else {
            config.body = JSON.stringify(data);
        }

        const url = msURL + '/' + action + getParams;

        return await fetch(url, config)
            .then((responseRaw) => {
                const responseJson = responseRaw.json();
                if (responseRaw.ok) {
                    return responseJson;
                }
                switch (responseRaw.status) {
                    case 403:
                    case 401:
                        sAction.error('Unauthorized on microservices');
                        console.error('Unauthorized on microservices', responseJson);
                        return {data: {}};
                    default:
                        sAction.error('Error when calling on microservices');
                        console.error('Error when calling on microservices', responseJson);
                        return {data: {}};
                }
            }).then((response) => {
                return response.data;
            });
    }

    userOffline() {
        const counter = this.parent.dataGet('conf/offline/counter');
        this.parent.dataSet('conf/offline/status', true);
        this.parent.dataSet('conf/offline/counter', counter + 1);
        if (counter >= 2) {
            // set more frequent check
            this.parent.notificationIntervalDuration = 5000;
            clearInterval(this.parent.notificationInterval);
            this.parent.notificationInterval = setInterval(() => {
                this.parent.notificationIntervalFunction();
            }, this.parent.notificationIntervalDuration); // 5s
        }
    }
}
